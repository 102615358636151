.blog-article{
    .blog-article-span{
        margin-bottom: 20px;
        h3{
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            text-transform: initial;
            margin-bottom: 24px;
            color: $dark-grey;
        }
        h2{
            color: $dark-grey;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            text-transform: initial;
            line-height: 48px;
            margin-bottom: 24px;
        }
        ol, ul{
            li {
                margin-bottom: 16px;
            }
        }
    }
    .rte-component{
        padding: 20px 0px;
            .col-lg-12{
                padding: 0px;
            }
        .rte-inner {
            padding: 0px;
        }
    }
    .blog-header{
        background:linear-gradient(262deg, #0076CA 0.88%, #005A9C 57.38%);
        padding: 40px 0px;
        color: $color-white;
        text-align: center;
        margin-bottom: 40px;
        @media screen and (max-width: $bp-lg) {
            padding: 24px 32px;
        }
        .blog-header-category{
            color: $color-white;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-transform: uppercase;
            margin-bottom: 8px;
        }
        .blog-header-title{
            color: $color-white;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 48px;
            @media screen and (max-width: $bp-lg) {
                line-height: 40px;
                font-size: 32px;
                margin-bottom: 8px;
            }
        }
        .blog-header-discription{
            color:$color-white;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-transform: none;
            @media screen and (max-width: $bp-lg) {
                margin-bottom: 0px;
            }
        }
    }
    .blog-details{
        padding: 0px 0px;
        @media screen and (max-width: $bp-lg) {
            padding: 0px 24px;
        }
        .blog-carousel{
            .img-caption{
                margin-bottom: 16px !important;
            }
        }
        .slick-prev{
            z-index: 1;
            left: -8.5%;
            width: auto;
            height: auto;
            &::before{
                background: url(../images/left-arrow.svg);
                height: 40px;
                width: 40px;
                content: '';
                display: block;
                background-repeat: no-repeat;
                @media screen and (max-width: $bp-tablet) {
                    height: 32px;
                    width: 32px;
                    background-size: 100%;
                }
                // @media screen and (max-width: $bp-md) {
                //     height: 42px;
                //     width: 42px;
                //     background-size: 100%;
                // }
            }
            @media screen and (max-width: $bp-tablet) {
                bottom: -24px;
                top: auto;
                right: auto;
                left: 0px;
            }
            @media screen and (min-width: $bp-tablet) and (max-width: $bp-lg) {
                bottom: -24px;
                top: auto;
                right: 0;
                left: 0%;
            }
        }
        .slick-next{
            z-index: 1;
            right: -8.5%;
            width: auto;
            height: auto;
            &::before{
                background: url(../images/right-arrow.svg);
                height: 40px;
                width: 40px;
                content: '';
                display: block;
                background-repeat: no-repeat;
                @media screen and (max-width: $bp-tablet) {
                    height: 32px;
                    width: 32px;
                    background-size: 100%;
                }
            }
            @media screen and (max-width: $bp-tablet) {
                bottom: -24px;
                top: auto;
                right: 0px;
            }
            @media screen and (min-width: $bp-md) and (max-width: $bp-lg){
                bottom: -24px;
                top: auto;
                right: 4%;
            @media screen and (min-width: $bp-tablet) and (max-width: $bp-lg) {
                right: 0%;
                bottom: -24px;
                top: auto;
            }
        }
        }
        .slick-dots{
            position: relative;
            bottom: 13px;
            li{
                width: auto;
                height: auto;
                pointer-events: none;
                button{
                    border-radius: 100px;
                    background: $neutral-gray;
                    transition: opacity 200ms ease;
                    width: 8px;
                    height: 8px;
                    &::before{
                    content: '';
                    }
                }
                &.slick-active{
                    button {
                        background: $dark-mid-green;
                        width: 32px;
                        // height: 30px;
                    }
                }
                &:hover {
                    span {
                        background: $dark-mid-green;
                    }
                }
            }
            @media screen and (max-width: $bp-md) {
                bottom: 0px;
            }
        }
        .blog-personal-social{
            padding: 16px 0px;
            display: flex;
            margin-bottom: 24px;
            justify-content: space-between;
            border-bottom: 1px solid $neutral-gray;
            align-items: center;
            .blog-personal{
                .blog-peronal-name{
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    margin-bottom: 0px;
                    color: $dark-grey;
                }
                .blog-date{
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    margin-bottom: 0px;
                    color: $dark-grey;
                }
            }
            .blog-share{
                p{
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px;
                    margin-bottom: 0px;
                    color: $dark-grey;
                    display: flex;
                    align-items: center;
                    span{
                        margin-right: 8px;
                    }
                    img{
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .blog-highlight{
            background: $light-grey;
            border-radius: 8px;
            padding: 16px 24px;
            margin-bottom: 24px;
            p{
                margin-bottom: 0px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 0px;
                color: $dark-grey;
            }
        }
        .blog-content{
                color: $dark-grey;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height:24px;
                .blog-article-span{
                    margin-bottom: 20px;
                }
            p{
                color: $dark-grey;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height:24px;
                margin-bottom: 40px;
                // &:last-child{
                //     margin-bottom: 0px;
                // }
                a{
                    color: $formal-blue;
                    text-decoration: underline;
                    font-weight: 400;
                    span{
                        color: $formal-blue !important;
                    }
                }
                .mdfl-tartiary-primary{
                    text-decoration: none;
                    font-weight: 700;
                }
            }
            .list-title{
                color: $dark-grey;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height:24px;
                margin-bottom: 8px;
            }
            ul, ol{
                margin-bottom: 40px;
                li{
                    color: $dark-grey;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height:24px;
                    a{
                        color: $formal-blue;
                        text-decoration: underline;
                        font-weight: 400;
                        span{
                            color: $formal-blue !important;
                        }
                    }
                }
            }
            a{
                color: $formal-blue;
                text-decoration: underline;
                font-weight: 400;
                span{
                    color: $formal-blue !important;
                }
            }
            table{
                a{
                    color: $formal-blue;
                    text-decoration: underline;
                    font-weight: 400;
                    span{
                        color: $formal-blue !important;
                    }
                }
            }
            h4{
                text-transform: initial;
            }
            h5{
                color: $dark-grey;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height:32px;
                margin-bottom: 8px;
                text-transform: initial;
            }
            .blog-share-bottom{
                color: $dark-grey;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height:20px;
                padding-top: 20px;
                span{
                    margin-right: 8px;
                }
                img{
                    width: 24px;
                    height: 24px;
                }
            }
            .img-caption{
                margin-bottom: 40px;
                border-bottom: 1px solid $neutral-gray;
                img{
                    width: 100%;
                    margin-bottom: 12px;  
                    border-radius: 8px;
                    aspect-ratio: 16 / 9;
                    object-fit: cover;
                }
                h6{
                    color: $dark-grey;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height:16px;
                    margin-bottom: 16px;
                }
            }
        }
    }
}