.questionBar{
    background:linear-gradient(262deg, $light-green 0%, $background-green 66%);
    padding: 40px 0px;
    @media screen and (max-width: $bp-lg) {
        padding: 80px 16.5px 24px;
    }
    .questionTitle{
        color:$color-white;
        font-size:28px;
        font-style: normal;
        font-weight: 700;
        line-height:36px;
        text-align: center;
        text-transform: capitalize;
        margin-bottom: 24px;
        @media screen and (max-width: $bp-lg) {
            margin-bottom: 16px;
        }
    }
    .white-color{
        border-radius: 8px;
        .col-lg-3{
            flex: 1;
            @media screen and (max-width: $bp-lg) {
                flex: auto;
            }
            &:last-child{
                .questionSection{
                    border-right:0px;
                    @media screen and (max-width: $bp-lg) {
                        border-bottom:0px;
                    }
                }
            }
        }
    }
    .questionSection{
        display: flex;
        width: 100%;
        padding:16px 9px;
        align-items: center;
        // padding-left: 0px;
        // padding-right: 4px;
        border-right: 1px solid $neutral-gray;
        min-height: 80px;
        height: 100%;
        @media screen and (max-width: $bp-lg) {
            border-bottom: 1px solid $neutral-gray;
            border-right: 0px;
            padding:16px;
        }
        img{
            width: 48px;
            height: 48px;
        }
        .mdfl-tartiary-primary{
            margin-left: 8px;
            margin-top: 0px;
            text-transform: capitalize;
        }
    }
}