.table{
    &-wrapper{
        padding: 0 0px;
        @media screen and (max-width: $bp-lg) {
            padding: 0 0 0 16.5px;
        }
        .table-container {
            width: 100%;
            // max-width: 100px;
            overflow-x: auto;
            position: relative;
            margin: 0 auto;
            scroll-behavior: smooth;
            border: 1px solid #D4D5D6;
            border-radius: 8px;
            box-sizing: border-box;
            @media screen and (max-width: $bp-lg) {
                margin-right: -10px;
                width: calc(100% + 10px);
                border-radius: 0px;
                padding-right: 10px;
                border:none;
                border-left: 1px solid #D4D5D6;
                padding-right: 24px;
            }
        
            th,
            td {
                border: 1px solid #D4D5D6;
                padding: 8px;
                text-align: left;
            }
        
            td.sticky-col,
            td {
                padding: 16px 8px;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                min-width: 295px;
                @media screen and (max-width: $bp-lg) {
                    min-width: 122px;  
                }
        
                &:first-child {
                    font-weight: 700;
                }
            }
        
            th,
            th.sticky-col {
                background-color: #ECF6FE;
                padding: 8px;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                @media screen and (max-width: $bp-lg) {
                    font-size: 16px;
                }
            }
        
            .sticky-col {
                position: sticky;
                left: 0;
                background-color: #fff;
                z-index: 1;
                white-space: nowrap;
                @media screen and (max-width: $bp-lg) {
                    white-space: wrap;
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: calc(100% + 1px);
                    background-color:#D4D5D6;
                    right: -1px;
                    top:-1px;
                }
            }
        
            table {
                width: 100%;
                color:#404040;
                border-collapse: collapse;
                @media screen and (max-width: $bp-lg) {
                    border: 1px solid #D4D5D6;
                    border-left:none;
                }
        
                thead{
                    tr{
                        th{
                            border-top:0px; 
                            &:first-child{
                                border-left:0px;
                            }
                            &:last-child{
                                border-right:0px; 
                                @media screen and (max-width: $bp-lg) {
                                    border-right:1px; 
                                }
                            }
                        }
                        td{
                            &:last-child{
                                border-right:0px;  
                                @media screen and (max-width: $bp-lg) {
                                    border-right:1px; 
                                }
                            }
                        }
                    }
                }
                tbody{
                    tr{
                        th, td{
                            &:first-child{
                                border-left:0px;  
                            }
                            &:last-child{
                                border-right:0px;
                                @media screen and (max-width: $bp-lg) {
                                    border: 1px solid #D4D5D6;
                                }
                            }
                        }
                        &:last-child{
                            td, th{
                                border-bottom: 0px;
                            }
                        }
                    }
                }
            }
        
           
        }
    }  
}

.table-section {
    padding-bottom: 40px;
    padding-top: 40px;
    @media screen and (max-width: $bp-lg) {
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .button-container {
        margin-top: 10px;
        text-align: center;
        @media screen and (max-width: $bp-lg) {
            padding-right: 24px;
        }
    }

    .scroll-buttons {
        display: flex;
        justify-content: space-between;

        button {
            font-size: 0px;
            background: transparent;
            width: 32px;
            height: 32px;
            border: none;
            border-radius: 50px;
            position: relative;
            background-image: url('../images/Icon/angle-icon.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;

            &:first-child {
                transform: rotate(180deg);
            }
        }
    }

    .nav-dots {
        text-align: center;
        margin-top: -30px;
        height: 28px;
    }

    .nav-dots span {
        height: 8px;
        width: 8px;
        margin: 0 3px;
        background-color: #D4D5D6;
        border-radius: 10px;
        display: inline-block;
        cursor: pointer;
        transition: width 0.5s;
    }

    .nav-dots .active {
        background-color: #00937F;
        width: 32px;
        border-radius: 10px;
    }

    .scroll-buttons button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}


.accordion{
    .accordion-item{
        .accordion-collapse{
            .accordion-body {
                .table-section{
                    padding-top: 0px;
                    padding-bottom: 0px;
                    td {
                        padding: 8px;
                        border-top: none;
                        a {
                            color: #042968;
                            text-decoration: underline;
                            font-weight: 400;
                        }
                        p, ul {
                            margin-bottom: 8px;
                        }
                        ul{
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}


