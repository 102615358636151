//background gradiant
@mixin linear-gradient($direction, $color-stops...) {
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
  }
//button
  @mixin button($bgcolor, $radius, $color){
    background: $bgcolor;
    border-radius: $radius;
    color: $color;
  }
  
  @mixin box-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $color: $default) {
    box-shadow: $x-axis $y-axis $blur $color;
  }
  