.testimonial-carousel {
  padding: 40px 0;
  &-item-wrapper {
    .testimonial-item {
      display: flex;
      column-gap: 40px;
      @media (max-width: 991px) {
        column-gap: 0;
        row-gap: 24px;
        flex-direction: column;
      }
      &-left {
        flex: 0 1 50%;
        text-align: left;
        align-content: flex-start;
        padding: 0px 24px;
        @media (max-width: 991px) {
          flex: 0 1 100%;
          order: 2;
        }
        h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 36px;
          color: $background-green;
          margin-bottom: 16px;
          @media (max-width: 991px) {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
          }
        }
        h3 {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: $dark-grey;
        }
      }
      &-right {
        flex: 0 1 50%;
        text-align: right;
        align-content: start;
        @media (max-width: 991px) {
          flex: 0 1 100%;
          order: 1;
        }
        img {
          display: inline-block;
          max-width: 530px;
          max-height: 440px;
          width: 100%;
          border-radius: 8px 120px 8px 8px;
          @media (max-width: 991px) {
            border-radius: 8px 104px 8px 8px;
            max-height: 221px;
            height: auto;
          }
        }
      }
    }

    &.slick-slider {
      width: 100%;
      background-color: transparent;
      .slick-track {
        display: flex;
        align-items: stretch;
        flex-wrap: nowrap;
        justify-content: center;
      }
      .slick-slide {
        float: none;
        display: inline-block;
        vertical-align: middle;
        padding: 24px;
        margin: 24px 12px;
        transition: all 0.3s ease;
        min-height: 448px;
        border-radius: 16px;
        background-color: $color-white;
        border: 1px solid $neutral-gray;
        display: flex !important;
        height: inherit !important;
        @media (max-width: 991px) {
          min-height: 700px;
          margin: 8px;
          align-items: start;
        }
        &.slick-current,
        &.slick-center {
          padding: 24px;
          align-items: start;
          @media (max-width: 991px) {
            align-items: start;
          }
        }
      }
    }

    .slick-prev,
    .slick-next {
      z-index: 10;
      top: 0px;
      background-color: transparent;
    }
    .slick-prev {
      left: 10px;
    }
    .slick-next {
      right: 10px;
    }
  }
  &-controls {
    margin: auto;
    display: flex;
    margin-top: 18px;
    justify-content: space-between;
    width: calc(100% - 33%);
    @media (max-width: 991px) {
      width: calc(100% - 15%);
    }
    .testimonial-carousel-slide-count {
      padding: 0 0 0 20px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: $dark-grey;
      span {
        font-weight: 500;
        display: inline-block;
        padding: 0 4px;
      }
    }
    &-arrows {
      min-width: 70px;
      .testimonial-carousel-arrows-prev.slick-arrow,
      .testimonial-carousel-arrows-next.slick-arrow {
        width: 32px;
        height: 32px;
        position: relative;
        display: inline-block;
        cursor: pointer;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 0px 0 0px 9px;
        border: 2px solid $formal-blue;
        background-color: $color-white;
        transition: all 0.3s ease;
        top: -3px;
        &:hover,
        &:focus {
          background-color: $formal-blue;
          svg {
            path {
              stroke: $color-white;
            }
          }
        }
      }
      .testimonial-carousel-arrows-prev.slick-arrow {
        margin-right: 10px;
      }
      .testimonial-carousel-arrows-next.slick-arrow {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  .testimonial-carousel-wrapper {
    &.single-testimonial {
      float: none;
      display: inline-block;
      vertical-align: middle;
      padding: 24px;
      margin: 24px auto;
      transition: all 0.3s ease;
      min-height: 448px;
      border-radius: 16px;
      background-color: $color-white;
      border: 1px solid $neutral-gray;
      display: flex !important;
      height: inherit !important;
      width: 67%;
      @media (max-width: 991px) {
        min-height: 700px;
        margin: 8px auto;
        align-items: start;
        width: 85%;
      }
      .testimonial-carousel-controls {
        display: none !important;
      }
    }
  }
}
