.jumplink-section{
    padding: 40px 0px;
    min-height: 150px;
    // margin-bottom: 14px;
    // padding-top: 60px;
    // position: sticky;
    
    // margin-bottom: 94px;
    @media (max-width: $bp-sm) {
        padding: 24px 8px 0px;
        min-height: 178px;
        margin-bottom: 0px;
    }
    @media (max-width: $bp-sm-landscap) {
        padding: 24px 8px;
        min-height: 136px;
        margin-bottom: 0px;
    }
}
#jumplinks-navbar {
    border-radius: 8px;
    border: 1px solid $neutral-gray;
    background: $color-white;
    box-shadow: 0px 2px 8px 0px $neutral-gray;
    padding: 8px 8px 8px 24px;
    width: 108%;
    top: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    // margin-bottom: 14px;
    @media (max-width: $bp-lg) {
        padding: 8px;
        align-items: stretch;
        // margin-bottom: 12px;
        flex-direction: column;
        width: 100%;
    }
    @media screen and (min-width: $bp-md) and (max-width: $bp-lg) {
        padding: 8px;
    }
    .jump-leftbar{
        display: block;
        width: 11%;
        margin-right: 16px;
        @media screen and (min-width: $bp-xl) and (max-width: $bp-xxxl) {
            width: 13%;
        }
        @media screen and (min-width: $bp-lg) and (max-width: $bp-xl) {
            width: 17%;
        }
        @media screen and (max-width: $bp-lg) {
            // margin-bottom: 8px;
            width: 100%;
        }
        .jumplink-heading{
            border-right: 1px solid $neutral-gray;
            padding: 8px 16px 8px 0px;
            color: $dark-grey;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-transform: uppercase;
            display: block;
            @media screen and (max-width: $bp-lg) {
                border-right: none;
                padding: 0px 0px 8px 8px;
            }
        }
    }
    .jump-mainbar{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .btn-mdfl-primary{
            margin-top: 0px;
        }
        @media screen and (max-width: $bp-lg) {
            align-items: stretch;
        }
        .btn-mdfl-primary{
            margin-top: 0px;
            @media screen and (max-width: $bp-md) {
                min-width: 49%;
            }
        }
        select {
            color: #042968;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            background: url('../images/Icon/down-arrow-blue.svg') no-repeat 98% 50%;
            line-height: 24px;
            -webkit-appearance: none;
        }
        ul{
            margin-bottom: 0px;
            padding-left: 0px;
            width: 89%;
            @media screen and (max-width: $bp-xxxl) {
                width: 87%;
            }
            @media screen and (min-width: $bp-xl) and (max-width: $bp-xxl) {
                width: 84%;
            }
            @media screen and (min-width: $bp-lg) {
                width: 78%;
                flex: 1;
            }
            @media screen and (min-width: $bp-md) and (max-width: $bp-lg) {
                padding-left: 0px;
            }
            li{
                display: inline-block;
                padding: 8px 16px;
                @media screen and (min-width: $bp-md) and (max-width: $bp-lg) {
                    padding: 8px 13px;
                }
                @media screen and (max-width: $bp-sm-landscap) {
                    padding: 8px 8px;
                }
                @media screen and (min-width: $bp-md) and (max-width: $bp-xl) {
                    padding: 8px 9px;
                }
                a{
                    text-decoration: none;
                    color: $formal-blue;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    // padding: 31px 0px;
                    display: block;
                    transition: 0.8s;
                    span{
                        padding: 8px 0px;
                        border-bottom: 2px solid $transparent;
                        &.active-link{
                            border-bottom: 2px solid $background-green;
                            color: $background-green;
                        }
                    }
                    &:hover{
                        span{
                            border-bottom: 2px solid $formal-blue;
                            transition: 0.8s;
                        }
                    }
                }
            }
        }
        #dropdown-menu {
            display: none;
            border-radius:  4px;
            border: 1px solid  $neutral-gray;
            // background: $color-white;
            padding: 8px 23px 8px 16px;
            min-width: 48%;
            margin-right: 8px;
            flex: 1;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis; 
        }
    }
   
    
    /* Sticky menu */
    &.sticky {
        position: fixed;
        top: 24px;
        // width: 85%;
        left: 50%; 
        transform: translateX(-50%); 
        @media (max-width: $bp-md) {
            left: 50%;
            width: calc(100% - 8%);
        }
        @media (max-width: $bp-tablet) {
            // width: 96%;
            left: 50%;
            width: calc(100% - 8%);
        }
        @media screen and (min-width: $bp-md) and (max-width: $bp-xxl) {
            left: 50%;
            width: calc(100% - 4%);
        }
    }

}
/* Media query for mobile devices */
@media (max-width: $bp-lg) {
    #jump-links {
        display: none;
    }
    #dropdown-menu {
        display: block !important;
        width: 53%;
    }
}

#content {
    padding-top: 60px;
}
