.event-hero{
    .hero-left-text{
        .share{
            // margin-bottom: 16px;
            margin-top: 24px;
            color: $color-white;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            a{
                margin: 0px 2px;
                &:first-child{
                    margin-left: 8px ;
                }
                &:last-child{
                    margin-right: 0px ;
                }
            }
        }
        .hero-main-title{
            margin-bottom: 8px;
        }
        .event-hero-date-time{
            margin-bottom: 16px;
            color: $color-white;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            text-transform: capitalize;
            @media screen and (max-width: $bp-lg) {
                font-size: 16px;
            }
        }
        .event-hero-placedetail{
            margin-bottom: 24px;
            color:$color-white;
            font-size:16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            text-transform: capitalize;
            @media screen and (max-width: $bp-lg) {
                margin-bottom: 16px;
            }
        }
        .hero-text{
            @media screen and (max-width: $bp-lg) {
                margin-bottom: 0px !important;
            }
        }
    }
    .hero-banner{
        .event-banner-image{
            width: 100%;
            border-radius: 16px;
            -o-object-fit: cover;
            object-fit: cover;
        }
    }
}
// .event-details-main{
//     margin-top: 40px;
    
// }
.location-event-hero{
    .hero-main{
        .hero-left-text{
            .hero-span{
                font-size: 18px !important;
                line-height: 24px !important;
                text-transform: capitalize !important;
                @media screen and (max-width: $bp-lg) {
                    font-size: 16px !important;
                    line-height: 24px !important;
                }
            }
            .event-hero-placedetail{
                margin-bottom: 8px;
            }
        }
    }
}
.location-details{
    margin-top: 40px;
    @media screen and (max-width: $bp-lg) {
        margin-top: 16px;
    }
    .hero-span{
        font-size: 18px;
        line-height: 24px;
        text-transform: capitalize;
        @media screen and (max-width: $bp-lg) {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .event-hero-placedetail{
        margin-bottom: 8px;
        text-transform: capitalize;
        @media screen and (max-width: $bp-lg) {
            margin-bottom: 0;
        }
    }
    .location-content{
        .accordion-item{
            &:first-child{
                .accordion-header{
                    .accordion-button{
                        border-top: 1px solid #D4D5D6;
                    }
                }
            }
            .accordion-body{
                padding: 24px 16px;
            }
        }
        @media screen and (max-width: $bp-sm-landscap) {
            order: 2;
            margin-top: 16px;
        }
    }
    .location-map{
        @media screen and (max-width: $bp-sm-landscap) {
            order: 1;
        }
        iframe{
            width: 100%;
            @media screen and (max-width: $bp-md) {
                padding: 0px 16.5px;
            }
        }
    }
}
.related-events{
    .mdfl-slider{
        justify-content: center;
        // .mdfl-inner-slider{
        //     flex: none;
        //     width: 50% !important;
        // }
        &:not(.slick-slider){
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}