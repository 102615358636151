.breadcrumb-main{
    margin-bottom: 0px;
    padding: 4px 0px;
    width: 100%;
    background: $color-white;
    @media screen and (max-width: $bp-lg) {
        padding: 4px 16.5px;
    }
    .breadcrumb{
        margin-bottom: 0px;
        .breadcrumb-item{
            padding-left: 8px;
            font-size: 12px;
            font-style: normal;
            line-height: 16px;
            @media screen and (max-width: $bp-lg) {
                padding: 2px 0px;
            }
            &:first-child{
                padding-left: 0px;
            }
            &::before{
                color: $dark-grey;
                padding-right: 8px;
            }
            a{
                color: $dark-grey;
                text-decoration-line: underline;
                font-weight: 400;
            }
            &.active{
                margin-bottom: 0px;
                color: $dark-grey;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }
    } 
}