// .blog-inner-header{
//     .half-banener-img{
//         position: relative;
//         width: 100%;
//         height: 100%;
//         margin-bottom: -52%;
//         img{
//             width: 100%;
//         }
//     }
// }
// .blog-inner-details{
//     padding-top: 62%;
// }
.blog-article{
    .blog-inner-header{
        background:linear-gradient(262deg, #0076CA 0.88%, #005A9C 57.38%);
        padding: 40px 0px;
        color: $color-white;
        text-align: center;
        margin-bottom: 24px;
        @media screen and (max-width: $bp-lg) {
            padding: 24px 9px;
        }
        .blog-header-category{
            color: $color-white;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-transform: uppercase;
            margin-bottom: 8px;
        }
        .blog-header-title{
            color: $color-white;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 48px;
            @media screen and (max-width: $bp-lg) {
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 8px;
            }
        }
        .blog-header-discription{
            color:$color-white;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-transform: none;
            margin-bottom: 24px;
            
        }
        .half-banener-img{
            position: relative;
            width: 100%;
            // height: 100%;
            margin-bottom: -18%;
            @media screen and (max-width: $bp-lg) {
                margin-bottom: -13%;
            }
            @media screen and (max-width: $bp-tablet) {
                margin-bottom: -20%;
            }
            img{
                width: 100%;
                min-height: 560px;
                max-height: 560px;
                object-fit: cover;
                border-radius: 8px;
                @media screen and (max-width: $bp-lg) {
                    min-height: auto;
                    max-height: none;
                }
            }
        }
    }
    .blog-inner-details{
        padding: 0px 0px;
        padding-top: 7.56%;
        @media screen and (max-width: $bp-lg) {
            padding: 0px 24px;
            padding-top: 7%;
        }
        
        .blog-personal-social{
            padding: 16px 0px;
            display: flex;
            margin-bottom: 40px;
            justify-content: space-between;
            border-bottom: 1px solid $neutral-gray;
            align-items: center;
            padding-top:0px;
            .blog-personal{
                .blog-peronal-name{
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    margin-bottom: 0px;
                    color: $dark-grey;
                }
                .blog-date{
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    margin-bottom: 0px;
                    color: $dark-grey;
                }
            }
            .blog-share{
                p{
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px;
                    margin-bottom: 0px;
                    color: $dark-grey;
                    display: flex;
                    align-items: center;
                    span{
                        margin-right: 8px;
                    }
                    img{
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .blog-highlight{
            background: $light-grey;
            border-radius: 8px;
            padding: 16px 24px;
            margin-bottom: 24px;
            p{
                margin-bottom: 0px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 0px;
                color: $dark-grey;
            }
        }
        .blog-content{
                color: $dark-grey;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height:24px;
            p{
                color: $dark-grey;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height:24px;
                margin-bottom: 40px;
                a{
                    color: $formal-blue;
                    text-decoration: underline;
                    font-weight: 400;
                }
                .mdfl-tartiary-primary{
                    text-decoration: none;
                    font-weight: 700;
                }
            }
            .image-section{
                margin: 0px;
                p{
                    margin-bottom: 0px;
                }
            }
            .list-title{
                color: $dark-grey;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height:24px;
                margin-bottom: 8px;
            }
            ul{
                margin-bottom: 40px;
                li{
                    color: $dark-grey;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height:24px;
                }
            }
            h5{
                color: $dark-grey;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height:32px;
                margin-bottom: 8px;
            }
            .blog-share-bottom{
                color: $dark-grey;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height:20px;
                padding-top: 0px;
                display: flex;
                align-items: center;
                span{
                    margin-right: 8px;
                }
                img{
                    width: 24px;
                    height: 24px;
                }
            }
            .img-caption{
                margin-bottom: 40px;
                border-bottom: 1px solid $neutral-gray;
                img{
                    width: 100%;
                    margin-bottom: 12px;  
                    border-radius: 8px;
                }
                h6{
                    color: $dark-grey;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height:16px;
                    margin-bottom: 16px;
                }
            }
        }
    }
}
.blog-related-articles{
    .mdfl-slider{
       .slick-prev, .slick-next{
            @media screen and (max-width: $bp-lg) {
                bottom: -7%;
            }
        }
        .slider-heading{
            font-size: 28px !important;
            line-height: 36px !important;
            @media screen and (max-width: $bp-lg) {
                font-size: 24px !important;
                line-height: 32px !important;
            }
        }
    }
}