*{
    padding: 0;
    margin: 0;
    font-family: "Noto Sans";
}
:root{
    --bs-body-font-family: "Noto Sans";
}
body {
    margin: 0;
    color: $color-black;
    nav, .collapsed-nav-container {
    background-color: $color-white;
    font-family: "Noto Sans";
    // padding: 0px 16px;
    }
    header{
        // margin: auto 120px;
        @media screen and (max-width: $bp-lg) {
            margin: auto 24px;
        }
        nav {
            display: flex;
            flex-direction: column;
            align-items: center;
            // padding: 15px 0px;
            min-height: 50px;
            box-sizing: border-box;
            color: $formal-blue;
            .desktop-nav-container {
                width: 100%;
                align-items: center;
                .nav-menu-container{
                    @media (max-width: $bp-lg) {
                            display: none;
                    }
                    .nav-close-menu{
                        display: none;
                    }
                .top-menu{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding: 5px 0px;
                    align-items: center;
                    @media screen and (max-width: $bp-lg) {
                        order: 3;
                    }
                    .top-leftbar{
                        .top-leftbar-location{
                            text-decoration: none;
                            color: $formal-blue;
                            font-size:12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px;
                            img{
                                margin-right: 4px;
                            }
                        }
                    }
                    .top-menu__rightbar{
                        text-align: right;
                        .top-menu__rightbar__item{
                            width: 100%;
                            display: inline-flex;
                            justify-content: flex-end;
                            align-items: center;
                            ul{
                                padding-left: 0px;
                                margin-bottom: 0px;
                                margin-right: 0px;
                                flex: 1;
                                li{
                                    display: inline-block;
                                    padding: 0px 8px;
                                    &:last-child{
                                        padding-right: 0px;
                                    }
                                    .top-menu-links{
                                        color: $formal-blue;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        text-decoration: none;
                                        line-height:16px;
                                        transition: 0.8s border-bottom;
                                        border-bottom: 1px solid $transparent;
                                        padding-bottom: 2px;
                                        transform: translateY(0);
                                        
                                    }
                                    &:hover{
                                        .top-menu-links{
                                            border-bottom: 1px solid $formal-blue;
                                            transform: translateY(100%);
                                        }
                                    }
                                    &:first-child{
                                            padding-left: 0px;
                                    }
                                }

                            }
                        }
                    }
                }
                .main-menu{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    border-top: 1px solid $neutral-gray;
                    border-bottom: 1px solid $neutral-gray;
                    padding: 8px 0px;
                    .main-menu__leftbar {
                        display: flex;
                        // width: 70%;
                        align-items: center;
                        .nav-brand{
                            margin-right: 20px;
                        }
                        // .nav-menu-container{
                            ul{
                                margin-bottom: 0px !important;
                                gap: 0px;
                                li{
                                    padding: 0px 20px;
                                    .nav-link{
                                        color: $formal-blue;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 700;
                                        line-height:20px;
                                        border-bottom: 0px solid $transparent;
                                        transition: 0.5s;
                                        padding: 4px 0px;
                                        &.active{
                                            border-bottom: 2px solid $background-green;
                                            color: $background-green;
                                            &:hover{
                                                border-bottom: 2px solid $background-green;
                                            }
                                        }
                                        &:hover{
                                            border-bottom: 2px solid $formal-blue;
                                            transition: 0.5s;
                                        }
                                    }
                                }
                            }
                        // }
                    }
                    .main-menu__rightbar{
                        display: flex;
                        // width: 30%;
                        justify-content: flex-end;
                        align-items: center;
                        .search-item{
                            position: relative;
                            .search-img{
                                position: absolute;
                                left: 2%;
                                top: 24%;
                                z-index: 1;
                            }
                            .input-search{
                                padding: 12px 8px 12px 29px;
                                border-radius: 4px;
                                min-width: 267px;
                                margin-right: 8px;
                                border: 1px solid $neutral-gray;
                                position: relative;
                                &::before{
                                    content: url('../images/Icon/search.svg');
                                    position: absolute;
                                    width: 12px;
                                    height: 12px;
                                    z-index: 10;
                                }
                            }
                        }
                        .login-item{
                            .btn-mdfl-secondary{
                                margin-top: 0px;
                            }
                        }
                    }
                }
                .sub-menu{
                    background: $natural-blue;
                    display: flex;
                    padding: 0px 0px;
                    flex-direction: column;
                    // border-bottom: 1px solid $neutral-gray;
                    .sub-main-menu{
                        display: flex;
                    }
                    .hidden-div{
                        min-width: 203px;
                        width: 203px;
                        height: 35px;
                        margin-right:33px;
                        display:none;
                    }
                    .submenu-inner{
                        .tab-content{
                            .tab-pane{
                                .home-menu {
                                    display: none;
                                }
                                // padding-bottom: 2px;
                                        ul{
                                            margin-bottom: 4px;
                                            padding-left: 0px;
                                            display: inline;
                                            li{
                                                padding: 7px 0px;
                                                margin: 0px 12px;     
                                                border-bottom: 2px solid $transparent;                 
                                                &:first-child{
                                                    padding-left: 0px;
                                                    margin-left: 0px;
                                                }
                                                // &.has_dropdown{
                                                //     &:hover{
                                                //         .submenu-item-btns{
                                                //             background: $color-white;
                                                //             border: 1px solid $background-green;
                                                //             color: $background-green;
                                                //             background: url(../images/Icon/select-arrow.svg) #ffffff no-repeat right;
                                                //             width: calc(100% + 2%);
                                                //             display: block;
                                                //             transition: width 0.5s ease-out;
                                                //             span{
                                                //                 margin-right: 10px;
                                                //                 transition: margin 0.5s ease-out;
                                                //             }
                                                //         }
                                                //     }
                                                //     .submenu-item-btns{
                                                //         width: 100%;
                                                //         transition: width 0.5s ease-out;
                                                //         display: inline-block;
                                                //         span{
                                                //             margin-right: 0px;
                                                //             transition: margin 0.5s ease-out;
                                                //         }
                                                //     }
                                                    
                                                // }
                                                .submenu-item-btns{
                                                    color: $formal-blue;
                                                    font-size: 12px;
                                                    font-style: normal;
                                                    font-weight: 700;
                                                    line-height: 16px; 
                                                    border: 1px solid $transparent;
                                                    background: $transparent;
                                                    text-decoration: none;
                                                    padding: 4px 8px;
                                                    border-radius: 4px;
                                                    transition: 0.4s background;
                                                    
                                                   

                                                    // &:first-child{
                                                    //     padding-left: 0px;
                                                    // }
                                                    &.active-mega-menu{
                                                        border: 1px solid $background-green;
                                                        background: $color-white;
                                                        color: $background-green;
                                                    }  
                                                    &:hover{
                                                        background: $color-white;
                                                        border: 1px solid $background-green;
                                                        color: $background-green;
                                                        // background: url(../images/Icon/select-arrow.svg) #ffffff no-repeat right;
                                                        // width: calc(100% + 2%);
                                                        // display: block;
                                                        // transition: width 0.5s ease-out;

                                                    }
                                                }
                                                &.mega_menu_dropdown {
                                                    position: static !important;
                                                    &:hover{
                                                        .submenu-item-btns{
                                                                background: $color-white;
                                                                border: 1px solid $background-green;
                                                                color: $background-green;
                                                        }
                                                        .sub_menu{
                                                            display: block;
                                                        }
                                                    }
                                                    .mega_menu {
                                                        left: 0;
                                                        right: 0;
                                                        background: $natural-blue;
                                                        display: flex;
                                                        flex-wrap: nowrap;
                                                        transition: 0.5s;
                                                        box-shadow: 0px 2px 8px 0px $neutral-gray;
                                                        padding: 24px 120px;
                                                        justify-content: space-between;
                                                        .col-lg-12{
                                                            &.d-flex{
                                                                @media screen and (max-width: $bp-lg) {
                                                                    flex-direction: column;
                                                                }
                                                            }
                                                        }
                                                        .mega_menu_item {
                                                            // width: 25%;
                                                            padding: 0px 12px;
                                                            flex: 1;
                                                            &:first-child{
                                                                padding-left: 0px;
                                                            }
                                                            &:last-child{
                                                                margin-right: 12px;
                                                            }
                                                            h3{
                                                                color:$dark-grey;
                                                                font-size: 12px;
                                                                font-weight: 700;
                                                                line-height: 16px;
                                                                text-transform: uppercase;
                                                                border-bottom: 1px solid $neutral-gray;
                                                                padding-bottom: 8px;
                                                            }
                                                            a{
                                                                color: $formal-blue;
                                                                font-size: 14px;
                                                                font-weight: 400;
                                                                line-height:20px;
                                                                margin: 8px 0px;
                                                                display: inline-block;
                                                                width: 100%;
                                                                text-decoration: none;
                                                                span{
                                                                    transition: 0.8s border;
                                                                    border-bottom: 1px solid $transparent;
                                                                }
                                                                &:hover{
                                                                    span{
                                                                        border-color: $formal-blue;
                                                                    }
                                                                }
                                                            }
                                                          }
                                                        .mega-menu-item-list{
                                                            min-width: 66%;
                                                            display: flex;
                                                            // max-width: 792px;
                                                            flex: 1;
                                                        }
                                                        .mega-menu-promo{
                                                            min-width: 30%;
                                                            display: block;
                                                            min-width: 384px;
                                                            max-width: 384px;
                                                            .mega_menu_promo-item{
                                                                border-radius: 8px;
                                                                padding: 16px;
                                                                background: $background-green;
                                                                img{
                                                                    width: 100%;
                                                                    max-height: 150px;
                                                                    min-height: 150px;
                                                                    border-radius: 4px;
                                                                    object-fit: cover;
                                                                }
                                                                .mega-promo-heading{
                                                                    color:$color-white;
                                                                    font-size: 24px;
                                                                    font-weight: 700;
                                                                    line-height: 32px;
                                                                    margin-top: 8px;
                                                                    margin-bottom: 8px;
                                                                    // margin-bottom: 8px;
                                                                }
                                                                // .promo-footer{
                                                                //     border-top: 1px solid $mid-dark-green;
                                                                //     // padding-top: 16px;
                                                                //     margin-top: 20px;
                                                                //     display: flex;
                                                                //     .btn-mdfl-primary-white, .btn-mdfl-secondary-white{
                                                                //         min-width: 167px;
                                                                //     }
                                                                // }
                                                            }
                                                        }
                                                      }
                                                  }  
                                                  .mega-sub-back-menu{
                                                    display: none;
                                                  }                                         
                                            }
                                        }
                               
                            }
                        }
                        .btn-mdfl-secondary{
                            margin: 0px;
                        }
                        ul{
                            margin-bottom: 0;
                            padding-left: 0px;
                            li{
                                display: inline-block;
                                padding: 4px 8px;
                                margin: 0px 12px;
                            }
                        }
                    }
                    
                }
                .nav-brand {
                    font-size: 1.8rem;
                }
               
                    ul{
                        width: 100%;
                        margin-bottom: 0px;
                        margin-right: 16px;
                        .nav-menu-item{
                            padding: 1% 3%;
                            display: inline-block;
                            font-size: 1.6rem;
                            font-weight: 700;
                            position: relative;
                            font-style: normal;
                            line-height: 24px;
                            &.active{
                                background-color: green-primary;
                            }
                            .nav-menu-dropdown{
                                display: none;
                                .sub-item{
                                    font-size: 1.0005em;
                                    padding: 4%;
                                    width: 100%;
                                    display: inline-block;
                                }
                            }
                            &:hover{
                                .nav-menu-dropdown{
                                    width: 100%;
                                    position: absolute;
                                    display: inline-block;
                                    padding: 10px 10px;
                                    background-color: $color-white;
                                    top: 100%;
                                    left: 0;
                                    min-width: 15rem;
                                    text-align: left;
                                    border-radius: 10px;
                                    box-shadow: 0px 0px 5px $formal-blue;
                                }
                            }
                        }
                        @media (max-width: $bp-lg) {
                            .nav-menu-item {
                              margin: 15px 0;
                              font-size: 2rem;
                            }
                          }
                    }
                    
               
            }
            .mobile-location{
                display: none;
            }
            .hamburger-btn {
                display: none;
                cursor: pointer;
                .h-menu,
                .h-close {
                  font-size: 1.8rem;
                }
                
                .h-close {
                  display: none;
                  &.hamburger-icon-show{
                    display: inline-block;
                    &>.mobile-logo{
                        display: none;
                    }
                    >.login-item{
                        display: none;
                    }
                  }
                 
                }
                .hamburger-hide {
                    display: none;
                }  
                .hamburger-toggle-show {
                //    animation: hamburger-toggle-show 0.1s ease 0s 1 forwards;
                animation: hamburger-toggle-show;
                animation-duration: 3s;
                animation-iteration-count: 2;
                animation-timing-function: ease-in;
                animation-direction: reverse;
                }
                .hamburger-icon-show {
                    display: inline-block;
                  }
                @keyframes hamburger-toggle-show {
                    0%   {left: 0px; top: 0px;}
                    100%  {left: 100px; top: 0px;}
                  }
                  
                  @keyframes slideNav {
                    from {
                        translatex: 150vw 0;
                    /*     scale: 200% 1; */
                      }
                    
                      to {
                        translatex: 0 0;
                    /*     scale: 100% 1; */
                      }
                  }
            }
            @media (max-width: $bp-lg) {
                .hamburger-btn {
                    display: inherit;
                    position: relative;
                    z-index: 1;
                    // top: 11px;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    min-height: 56px;
                    padding: 8px 16px;
                    display: flex;
                    border-bottom: 1px solid $neutral-gray;
                    .mobile-logo{
                        min-width: 167px;
                        max-width: 167px;
                    }
                    .menu-icon{
                        width: 44px;
                    }
                    .btn-mdfl-secondary{
                        padding: 4px 8px;
                        margin-top: 0px;
                    }

                }
                
                .mobile-location{
                    padding: 4px 16px;
                    display: block;
                    border-bottom: 1px solid $neutral-gray;
                    .top-mobile-location{
                        display: flex;
                        align-items: center;
                        .mobile-location-img{
                            margin-right: 4px;
                        }
                        color: $formal-blue;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height:16px;
                        text-decoration: none;
                    }
                }
            }
            }
             @media (max-width: $bp-lg) {
                    .nav-menu-container{
                        display: none;
                    }
                }
                @media (max-width: $bp-sm) {
                    .nav-brand{
                        width: 50%;
                        img{
                            width: 100%;
                        }
                    }
                    .hamburger-btn{
                        width: 50%;
                    }
                }
            .collapsed-nav-container {
                position: fixed;
                left: 0;
                right: 0;
                z-index: 10;
                display: flex;
                flex-direction: column;
                justify-content: start;
                transition: translate 1s ease-out;
                overflow: hidden;
                text-align: center;
                top: 0%;
                height: calc(100% - 93px);
                translate: -150vw 0;
                background: $natural-blue;
                z-index: 100;
                .nav-close-menu{
                    display: block;
                    text-align: left;
                    background: $color-white;
                    padding: 8px 16px;
                }
                .top-menu{
                    order: 3;
                    margin-bottom: 24px;
                    padding: 0px 16px;
                    .top-leftbar{
                        display: none;

                    }
                    .top-menu__rightbar{
                        // @media screen and (max-width: $bp-lg) {
                        //     padding-left: 8px;
                        // }
                        .top-menu__rightbar__item{
                            ul{
                                list-style: none;
                                text-align: left;
                                // margin: 12px 0px;
                                li{
                                    margin: 24px 0px;
                                    @media screen and (max-width: $bp-lg) {
                                        margin: 12px 0px;
                                        display: inline-block;
                                        width: 100%;
                                    }
                                    a{
                                        color:$formal-blue;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 20px;
                                        text-decoration: none;
                                    }
                                }
                            }
                            .btn-mdfl-secondary-sm{
                                // width: 100%;
                                text-align: center;
                                padding: 12px 16px;
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 24px;
                            }
                        }
                    }
                }
                .main-menu{
                    order: 1;
                    display: flex;
                    flex-direction: column;
                    margin-top: 16px;
                    padding: 0px 16px;
                    @media screen and (min-width: $bp-landscap) and (max-width: $bp-lg) {
                        .row{
                            flex-direction: column;
                        }
                    }
                    .main-menu__leftbar{
                        order: 2;
                        padding: 0px;
                        .nav-brand{
                            display: none;
                        }
                        .nav-menu-container-new{
                            @media screen and (min-width: $bp-md) and (max-width: $bp-lg) {
                                display: flex;
                            }
                        }
                        ul.nav {
                            border-radius: 64px;
                            border: 1px solid $neutral-gray;
                            padding: 4px;
                            justify-content: space-between;
                            gap: 0px;
                            background: $color-white;
                            @media screen and (max-width: $bp-xs) {
                                display: flex;
                                flex-wrap: nowrap;
                                flex-direction: row;
                            }
                            li{

                                @media screen and (min-width: $bp-xs) {
                                    min-width: 90px;
                                }
                                @media screen and (min-width: $bp-xs-sm) {
                                min-width: 110px;
                                }
                                .nav-link{
                                    padding: 8px 16px;
                                    color: $formal-blue;
                                    text-align: center;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 24px;
                                    transition: none;
                                    @media screen and (min-width: $bp-xs) and (max-width: 360px) {
                                        padding: 8px 9px;
                                    }
                                    // @media screen and (max-width: $bp-xs-sm) {
                                    //     padding: 8px 16px;
                                    // }
                                    &:hover{
                                        background-color: $formal-blue;
                                        color: $color-white;
                                        border-radius: 24px;
                                    }
                                    &.active{
                                        background: $background-green;
                                        color: $color-white;
                                        border-radius: 24px;
                                    }
                                }
                            }
                        }
                        
                    }
                    .main-menu__rightbar{
                        order: 1;
                        text-align: left;
                        padding: 0px;
                        .search-item{
                            padding: 0px 0px;
                            // background: $color-white;
                            position: relative;
                            .search-img{
                                position: absolute;
                                top: 24%;
                                left: 2%;
                                @media screen and (min-width: $bp-tablet) and (max-width: $bp-lg) {
                                    left: 1%;
                                }
                            }
                            .input-search{
                                width: 100%;
                                padding: 12px 8px 12px 32px;
                                border: 1px solid $neutral-gray;
                                border-radius: 4px;
                                color: $dark-grey;
                                @media screen and (max-width: $bp-lg) {
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }
                        }
                        .login-item{
                            display: none;
                        }
                    }
                }
                .sub-menu{
                    order: 2;
                    padding: 0px 9px;
                    .tab-content{
                        .tab-pane{
                            &.active{
                                text-align: left;
                                border-bottom: 1px solid $neutral-gray;
                                .btn-mdfl-primary-sm{
                                    padding: 12px 16px;
                                    font-size: 16px;
                                    line-height: 24px;
                                    margin: 24px 0px;
                                }
                            }
                            .home-menu {
                                display: none;
                                @media screen and (max-width: $bp-lg) {
                                    display: block;
                                    a{
                                        display: flex;
                                        color: $formal-blue;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 700;
                                        line-height: 24px;
                                        text-decoration: none;
                                        align-items: center;
                                        border-bottom: 1px solid $neutral-gray;
                                        text-align: left;
                                        padding: 16px 8px;
                                        justify-content: left;
                                        .btnarrow{
                                            margin-left: 8px;
                                            padding: 6px 0px;
                                            &::before{
                                                width: 25px;
                                            }
                                            &::after{
                                                width: 11px;
                                                height: 11px;
                                                top: calc(50% - 5px);
                                            }
                                        }
                                        img{
                                            margin-right: 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .main-submenu-ul{
                        list-style: none;
                        text-align: left;
                        margin-top: 0px;
                        li{
                            padding: 0px 0px;
                            // border-bottom: 1px solid $neutral-gray;
                            &.mega_menu_dropdown{
                                .submenu-item-btns{
                                    &::after{
                                        content: url('../images/Icon/mobile-nav-arrow.svg');
                                        width: 24px;
                                        height: 24px;
                                    }
                                }
                            }
                            .mega-menu-item-list{
                                padding: 0px 0px;
                            }
                            .submenu-item-btns {
                                color: $formal-blue;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 24px;
                                border: none;
                                background: transparent;
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                text-decoration: none;
                                padding: 16px 8px;
                                border-bottom: 1px solid $neutral-gray;
                            }
                            .mega-sub-back-menu{
                                display: none;
                                color: $formal-blue;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 20px;
                                border: none;
                                background: transparent;
                                width: 100%;
                                // display: flex;
                                justify-content: flex-start;
                                padding: 0px;
                                margin-top: 24px;
                                align-items: center;
                                &::before{
                                    content: url('../images/Icon/mega-back-button.svg');
                                    width: 24px;
                                    height: 24px;
                                    margin-right: 4px;
                                }
                            }
                            .sub_menu{
                                .mega_menu{
                                    position: relative;
                                    top: 24px;
                                    margin-top: 0px;
                                    .col-lg-12{
                                        padding: 0px;
                                    }
                                    .mega-menu-item-list{
                                        // margin-top: 24px;
                                        .mega_menu_item{
                                            &:first-child{
                                                border-top: 1px solid $neutral-gray;
                                            }
                                            .mega-menu-column-heading{
                                                color:  $formal-blue;
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 24px;
                                                text-transform: capitalize;
                                                margin-bottom: 0px;
                                                display: flex;
                                                justify-content: space-between;
                                                transition: transform 0.3s ease;
                                                transform: rotate(0deg);
                                                padding: 16px 8px;
                                                border-bottom: 1px solid $neutral-gray;
                                                &::after{
                                                    content: url('../images/Icon/mega-down-arrow.svg');
                                                    width: 24px;
                                                    height: 24px;
                                                }
                                                &.rotate::after {
                                                    transform: rotate(180deg);
                                                }
                                            }
    
                                            .mega-menu-column{
                                                background: $color-white;
                                                padding: 16px 8px;
                                                a{
                                                    display: block;
                                                    color: $formal-blue;
                                                    font-size:14px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height:20px;
                                                    padding: 12px;
                                                    text-decoration: none;
                                                    span{
                                                        transition: 0.8s border;
                                                        border-bottom: 1px solid $transparent;
                                                    }
                                                    &:hover{
                                                        span{
                                                            border-color: $formal-blue;
                                                        }
                                                    }
                                                    &:first-child{
                                                        padding-top: 0px;
                                                    }
                                                    &:last-child{
                                                        padding-bottom: 0px;
                                                    }
                                                }
                                            }
                                        }
                                        
                                    }
                                    .mega-menu-promo{
                                        margin-top: 40px;
                                        padding: 16px;
                                        background: $background-green;
                                        margin-bottom: 25%;
                                        border-radius: 8px;
                                        .mega_menu_promo-item{
                                            display: flex;
                                            width: 100%;
                                            flex-direction: column;
                                            .mega-promo-heading{
                                                color: $color-white;
                                                font-size: 24px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 32px;
                                                order: 1;
                                                margin-bottom: 0px;
                                            }
                                            .mdfl-tartiary-white{
                                                order: 2;
                                            }
                                            .mega-promo-img{
                                                order: 3;
                                                margin: 20px 0px 6px;
                                                width: 100%;
                                                height: auto;
                                                border-radius: 4px;
                                                max-height: 150px;
                                                min-height: 150px;
                                            }
                                            // .promo-footer{
                                            //     order: 4;
                                            // }
                                        }
                                    }
                                    .col-lg-12{
                                        &.d-flex{
                                            flex-direction: column;
                                        }
                                    }
                                    .mega-menu-column{
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
                .nav-menu-item{
                    &.active{
                        background-color: $green-primary;
                    }
                }
                .nav-menu-dropdown{
                    display: none;
                    width: 100%;
                    position: relative;
                    padding: 10px 10px;
                    top: 0%;
                    left: 0;
                    min-width: 15rem;
                    margin-top: 0px;
                    .sub-item{
                        font-size: 1.0005em;
                        padding: 1%;
                        width: 100%;
                        display: inline-block;
                    }
                }
                @media (max-width: $bp-sm) {
                    &.show-collapsed-nav-container {
                      top: 0%;
                    }
                }
                @media (max-width: $bp-lg) {
                    &.show-collapsed-nav-container {
                    //   animation: slideNav 0.4s ease-out 0s 1 forwards;
                    animation: slideNav;
                    animation-duration: 1s;
                    translate: 0vw 0;
                    top: 0%;
                    background: $natural-blue;
                    padding: 0px 0px;
                    overflow-y: scroll;
                    padding-bottom: 8px;
                    }
                    ul{
                        margin-top: 16px;
                        padding-left: 0px;
                        margin-bottom: 0px !important;
                        .nav-menu-item{
                            display: block;
                            font-size: 1.403em;
                            line-height: 2.4em;
                        }
                    }
                }
              }
              
            @media (max-width: $bp-lg) {
                .collapsed-nav-container{
                    height:100%
                }
            }
            @media (max-width: $bp-sm) {
                .collapsed-nav-container{
                    height:100%
                }
            }
          }
    }
    @media screen and (max-width: $bp-md) {
        header{
            margin: 0px 0px;
        }
    } 
  }
    .main_menu {
        ul {
            li{
                .sub_menu{
                    display: none;
                }
                .mega_menu{
                    position: absolute;
                    margin-top: 4px;
                    z-index: 99;
                }
            }
        }
      
    }
    .nav-fixed{
        position: fixed;
        top:0;
        background: $natural-blue;
        width: 100%;
        z-index: 10;
        padding: 0px 120px;
        border-bottom: 1px solid $neutral-gray;
        // .d-flex{
        //     align-items: center;
        // }
        @media screen and (max-width: $bp-lg) {
            display: none !important;
        }
        ul {
            li{
                &:hover{
                    .submenu-item-btns{
                            background: $color-white;
                            border: 1px solid $background-green;
                            color: $background-green;
                    }
                    .sub_menu{
                        display: block;
                    }
                }
                .sub_menu{
                    display: none;
                }
                &:hover{
                    .sub_menu{
                        display: block;
                    }
                }
                .mega_menu{
                    position: absolute;
                    margin-top: 6px;
                    z-index: 99;
                }
            }
        }
        .home-menu{
            display: none;
        }
        select#headerDropdown {
            border: transparent;
            background: url(../images/Icon/select-arrow.svg) no-repeat 107% 50%  transparent;
            -webkit-appearance: none;
            border-bottom: 2px solid $background-green;
            color: $background-green;
            font-weight: 700;
            font-size: 14px;
            width: auto;
            min-width: calc(100% - 95%);
            padding-right: 15px;
        }
        .tab-pane{
            .home-menu{
                display: none;
            }
            &.active{
                display: flex;
                align-items: center;
            }
            .btn-mdfl-primary-sm{
                margin-left: 12px;
            }
        }
        .main-submenu-ul{
            margin-bottom: 0px;
            li{
                display: inline-block;
                padding: 8px 0px;
                // &.has_dropdown{
                //     &:hover{
                //         .submenu-item-btns{
                //             background: $color-white;
                //             border: 1px solid $background-green;
                //             color: $background-green;
                //             background: url(../images/Icon/select-arrow.svg) #ffffff no-repeat right;
                //             width: calc(100% + 2%);
                //             display: block;
                //             transition: width 0.5s ease-out;
                //             span{
                //                 margin-right: 10px;
                //                 transition: margin 0.5s ease-out;
                //             }
                //         }
                //     }
                //     .submenu-item-btns{
                //         width: 100%;
                //         transition: width 0.5s ease-in;
                //         display: inline-block;
                //         span{
                //             margin-right: 0px;
                //             transition: width 0.5s ease-out;
                //         }
                //     }                   
                // }
                // margin: 4px 0px;
                .submenu-item-btns{
                    color: #042968;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px;
                    border: 1px solid transparent;
                    background: transparent;
                    text-decoration: none;
                    padding: 4px 12px;
                    border-radius: 4px;
                    transition: 0.4s background;
                    &.active-mega-menu {
                        border: 1px solid #008674;
                        background: #ffffff;
                        color: #008674;
                    }
                    // &:hover{
                    //     background: $color-white;
                    //     border: 1px solid $background-green;
                    //     color: $background-green;
                    // }
                }
                .mega-sub-back-menu{
                    display: none;
                }
            }
        }
        .sub_menu{
            display: none;
            .mega_menu{
                left: 0;
                right: 0;
                background: #ECF6FE;
                display: flex;
                flex-wrap: nowrap;
                transition: 0.5s;
                box-shadow: 0px 2px 8px 0px #D4D5D6;
                padding: 24px 120px;
                justify-content: space-between;
                .mega-menu-item-list{
                    min-width: 66%;
                    display: flex;
                    // max-width: 792px;
                    flex: 1;
                    .mega_menu_item{
                        padding: 0px 12px;
                        flex: 1;
                        h3{
                            color: #404040;
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 16px;
                            text-transform: uppercase;
                            border-bottom: 1px solid #D4D5D6;
                            padding-bottom: 8px;
                        }
                        a{
                            color: $formal-blue;
                            font-size: 14px;
                            font-weight: 400;
                            line-height:20px;
                            margin: 8px 0px;
                            display: inline-block;
                            width: 100%;
                            text-decoration: none;
                            span{
                                transition: 0.8s border;
                                border-bottom: 1px solid $transparent;
                            }
                            &:hover{
                                span{
                                    border-color: $formal-blue;
                                }
                            }
                        }
                    }
                }
                .mega-menu-promo{
                    min-width: 30%;
                    display: block;
                    min-width: 384px;
                    max-width: 384px;
                    .mega_menu_promo-item{
                        border-radius: 8px;
                        padding: 16px;
                        background: $background-green;
                        img{
                            width: 100%;
                            max-height: 150px;
                            min-height: 150px;
                            border-radius: 4px;
                            object-fit: cover;
                        }
                        .mega-promo-heading{
                            color:$color-white;
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 32px;
                            margin-top: 8px;
                            margin-bottom: 8px;
                            // margin-bottom: 8px;
                        }
                        // .promo-footer{
                        //     border-top: 1px solid $mid-dark-green;
                        //     // padding-top: 16px;
                        //     margin-top: 20px;
                        //     display: flex;
                        //     .btn-mdfl-primary-white, .btn-mdfl-secondary-white{
                        //         min-width: 167px;
                        //     }
                        // }
                    }
                }
                
            }
        }
    }
    .mobile-sticky-nav{
        position: fixed !important;
        top: 0;
        left: 0;
        background: $color-white !important;
        z-index: 2 !important;
        display: flex;
    }