 // modalbox styling
 .modalbox-wrapper {
   display: none;
   position: fixed;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   background: rgba(0, 0, 0, 0.8);
   z-index: 9999;

   .modalbox {
     position: fixed;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     width: 90%;
     max-width: 457px;
     height: auto;
     max-height: 100vh;
     background-color: white;
     padding: 32px;
     border-radius: 8px;
     color: #404040;


     .modalbox-header {
       position: absolute;
       right: 10px;
       top: 6px;
       width: 24px;
       height: 24px;
       text-align: center;
       line-height: 28px;

       .close {
         cursor: pointer;
         line-height: 20px;
         font-size: 20px;
         color: #404040;
       }
     }

     .modalbox-content {
       margin-bottom: 16px;

       .heading {
         font-size: 24px;
         line-height: 32px;
         text-align: center;
         font-weight: 700;
         @media all and (max-width:768px){
          font-size: 20px;
          line-height: 28px;
         }
       }

       .content {
         font-size: 16px;
         line-height: 24px;
         text-align: center;
         opacity: 1;
       }

       .modalbox-img {
         width: auto;
         max-width: 100%;
         height: auto;
         max-height: 80vh;
         display: block;
         margin: 0 auto;
       }
     }


     .modalbox-footer {
       display: flex;
       align-items: center;
       justify-content: center;
       gap: 16px;

       a{
        margin-top: 0px;
       }

     }
   }
 }