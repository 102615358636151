.promo-banner{
    padding: 0px 0px;
    @media screen and (max-width: $bp-md) {
        padding: 0px;
    }
    @media screen and (max-width: $bp-lg) {
        margin-right: 0px;
        padding: 0px 16.5px;
    }

    .promo-main{
        padding: 0px 0px;
        // padding: 24px 0px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-radius: 16px;
        @media screen and (max-width: $bp-md) {
            flex-direction: column;
            padding: 0px 0px;
        }
        @media screen and (max-width: $bp-lg) {
            padding: 0px 0px;
        }
        .promo-inner{
            display: inline-flex;
            width: 100%;
            align-items: center;
            border-radius: 16px;
            padding: 24px;
            margin: 40px 0px;
            // gap: 40px;
            flex-direction: row;
            flex-wrap: nowrap;   
            @media screen and (max-width: $bp-lg) {
                padding: 0px;
                margin: 24px 0px;
                gap: 0;
                .col-lg-6{
                    width: 50%;
                } 
            }
            @media screen and (max-width: $bp-md) {
                flex-direction: column; 
                .col-lg-6{
                    width: 100%;
                } 
            }
            .col-lg-6{
                &[data-section-order='right']{
                    order: 1;
                    padding-left: 40px;
                    @media screen and (max-width: $bp-lg) {
                        order: 1;
                    }
                }
                &[data-section-order='left']{
                    order: 0;
                    padding-right: 40px;
                    @media screen and (max-width: $bp-lg) {
                        order: 1;
                    }
                }
            }
            .promo-left{
                padding: 24px 0px;
                // width: 100%;
                margin-right: 40px;
                padding-left: 64px;
                &.full-width{
                    .promo-btns{
                        @media screen and (max-width: $bp-md){
                            .btn-mdfl-primary-white, .btn-mdfl-secondary-white, .btn-mdfl-secondary, .btn-mdfl-primary{
                                width: 100%;
                            }
                        }
                    }
                }
                @media screen and (max-width: $bp-md) {
                    width: 100%;
                    order: 1 !important;
                    padding: 16px 0px;
                    margin-right: 0px;
                    flex-direction:column !important;
                }
                @media screen and (max-width: $bp-xl) {
                    padding: 16px 16px 24px;
                }
                
                .promo-eyebrow{
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px; 
                    text-transform: uppercase;
                    color: $middle-blue;
                }
                .promo-heading{
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    color: $dark-grey;
                    text-transform: initial;
                     margin-bottom: 16px;
                     @media screen and (max-width: $bp-landscap) {
                        font-size: 24px;
                        line-height: 32px;
                    }
                }
                .promo-content{
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 0px;
                    color: $dark-grey;
                    @media screen and (max-width: $bp-landscap) {
                        margin-bottom: 0px;
                    }
                }
                .promo-content-section{
                    @media screen and (max-width:$bp-sm-landscap) {
                        width: 100% !important;
                        margin-right: 0px !important;
                    }
                }
                .promo-btns{
                    @media screen and (max-width: $bp-sm-landscap) {
                        width: 100% !important;
                    }
                    // .btn-mdfl-primary{
                    //     @media screen and (max-width: $bp-md) {
                    //         width: 100% !important;
                    //     }
                    // }
                }
    
            }
            .promo-right{
                // width: 50%;
                display: flex;
                justify-content: center;
                @media screen and (max-width: $bp-md) {
                    order: 0 !important;
                    width: 100%;
                    justify-content: left;
                    padding: 24px 16px 0px !important;
                }
                @media screen and (max-width: $bp-lg) {
                    padding: 24px;
                    padding-bottom: 0px;
                }
                @media screen and (max-width: $bp-tablet) {
                    justify-content: center;
                }
                .circle-darkgreen{
                    background-color: transparent;
                    // background-color: $mid-green;
                    border-radius: 400px;
                    @media screen and (max-width: $bp-tablet) {
                       width: 100%;
                    }
                    img{
                        width: 96%;
                        max-height: 400px;
                        min-height: 400px;
                        border-radius: 16px;
                        border-top-right-radius: 104px;
                        object-fit: cover;
                        aspect-ratio: 16 / 9;
                        @media screen and (min-width: $bp-lg) and (max-width: $bp-xl) {
                            min-height: auto !important;
                        }
                        @media screen and (max-width: $bp-lg) {
                            // min-height: 221px;
                        }
                        @media screen and (max-width: $bp-lg) {
                            min-height: auto !important;
                            max-height: none;
                            border-radius: 8px;
                            border-top-right-radius: 64px;
                            width: 100%;
                            aspect-ratio: 16 / 12;
                        }
                    }
                    .img-box{
                        border-radius: 0px;
                        width: 100%;
                        max-width: 400px;
                        min-height: 400px;
                        object-fit: contain;
                        @media screen and (max-width: $bp-lg) {
                            aspect-ratio: 16 / 16;
                        }
                    }
                }
                .videoplayer{
                    position: relative;
                    max-width: 587px;
                    width: 100%;
                    video{
                        max-width: 531px;
                        width: 100%;
                        max-height: 398px;
                        height: 420px;
                        border-radius: 16px;
                        border-top-right-radius: 104px;
                        object-fit: cover;
                        aspect-ratio: 16 / 9;
                        @media screen and (min-width: $bp-tablet)  and (max-width: $bp-md) {
                            min-height: 231px;
                            max-height: none;
                        }
                        @media screen and (max-width: $bp-tablet) {
                            min-height: 220px;
                            max-height: 220px;
                            width: 100%;
                        }
                    }
                    #controls {
                        position: absolute;
                        right: 11%;
                        bottom: 2%;
                        @media screen and (min-width: $bp-lg) and (max-width: $bp-xxl) {
                            right: 1%;
                        }
                        @media screen and (max-width: $bp-landscap) {
                            right: 1%;
                        }
                        @media screen and (min-width: $bp-tablet) and (max-width: $bp-md) {
                            right: 11%;
                        }
                        @media screen and (max-width: $bp-tablet) {
                            right: 1%;
                        }
                        
                        .pause-video{
                            max-width: inherit;
                            border-radius: 0px;
                            width: auto;
                            
                        }
                        .play-video{
                            max-width: inherit;
                            border-radius: 0px;
                            width: auto;
                            display: none;
                        }
                    }
                    iframe{
                        border-radius: 16px;
                        border-top-right-radius: 104px;
                        width: 96%;
                        @media screen and (max-width: $bp-tablet) {
                            width: 100%;
                            max-height: 231px;
                        }
                        @media screen and (max-width: $bp-xl) {
                            width: 100%;
                        }
                    }
                }
            }
            // &:last-child{
            //     &.mb-79{
            //         margin-bottom: 0;
            //     }
            // }
            &.green-gradient{
                border-radius: 16px;
                // padding: 24px;
                background:linear-gradient(262deg, $light-green 0%, $background-green 66%);
                &.promo-inner{
                    .promo-left{
                        .promo-eyebrow{
                            margin-bottom: 8px;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 20px; 
                            text-transform: uppercase;
                            color: $color-white;
                            @media screen and (max-width: $bp-landscap) {
                                font-size: 14px;
                            }
                        }
                        .promo-heading{
                            font-size: 32px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 40px;
                            color: $color-white;
                            text-transform: initial;
                            margin-bottom: 16px;
                            @media screen and (max-width: $bp-landscap) {
                                font-size: 24px;
                                line-height: 32px;
                            }
                        }
                        .promo-content{
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            margin-bottom: 0px;
                            color: $color-white;
                        }
        
                    }
                }
                
            }
        }
        
        
    }
}