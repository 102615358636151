body {
    header {
        nav {
            .desktop-nav-container {
                .nav-menu-container {
                    ul {
                        &.autocomplete-suggestions {
                            background: #fff;
                            border: 1px solid #042968;
                            border-radius: 4px;
                            overflow: hidden;
                            box-sizing: border-box;
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            position: absolute;
                            width: calc(100% - 8px);
                            z-index: 2;

                            li {
                                cursor: pointer;
                                font-size: 14px;
                                line-height: 20px;
                                padding: 4px 16px;

                                &:hover {
                                    background: #d4d5d6;
                                }

                                &.highlighted {
                                    background: #d4d5d6;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .collapsed-nav-container {
        ul {
            &.autocomplete-suggestions {
                background: #fff;
                border: 1px solid #042968;
                border-radius: 4px;
                overflow: hidden;
                box-sizing: border-box;
                list-style: none;
                margin: 0;
                padding: 0;
                position: absolute;
                width: calc(100% - 8px);
                z-index: 2;

                li {
                    cursor: pointer;
                    font-size: 14px;
                    line-height: 20px;
                    padding: 4px 16px;

                    &:hover {
                        background: #d4d5d6;
                    }

                    &.highlighted {
                        background: #d4d5d6;
                    }
                }
            }
        }
    }
}