.image {
    &-section {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    &-wrapper {
        width: 100%;
        border-radius: 8px;
        overflow: hidden;

        img {
            width: 100%;
            aspect-ratio: 16 / 9;
            object-fit: cover;
        }
    }

    &-caption {
        font-size: 12px;
        line-height: 16px;
        padding: 8px 0px;
        border-bottom: 1px solid $neutral-gray;
        color: $dark-grey;

        >* {
            margin: 0px;
            padding: 0px;
        }
    }
}