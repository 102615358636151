.hero-home-main{
    position: relative;
    @media screen and (max-width: $bp-lg) {
        background: $natural-blue; 
     }
    .hero-home-slide{
        .background-media{
            @media screen and (min-width: $bp-xl) {
                .slick-list{
                    height: 100% !important;
                }
            }
        }
        .videoplayer{
            .controls{
                position: relative;
                z-index: 100;
                width: 100%;
                max-width: 1200px;
                margin-left: auto;
                margin-right: auto;
                margin-top: -168px;
                // left: 9%;
                // bottom: 3%;
                img{
                    height: auto !important;
                    cursor: pointer;
                }
                @media screen and (max-width: $bp-lg) {
                    left: 5%;
                    margin-top: -14%;
                }
            }
        }
        .hero-main-text{
            width: 100%;
            display: block;
            position: absolute;
            top: 8%;
            // transform: translate(0, -50%);
            z-index: 1;
            @media screen and (max-width: $bp-lg) {
                background: linear-gradient(5deg, #0076CA 0%, #005A9C 100%);
                padding: 16px 8.5px;
                transform:none;
                position: relative;
                padding-bottom: 64px;
                border-bottom-right-radius:64px;
            }
        }
        .background-media{
            display: block;
            position: relative;
            background: $natural-blue;
            @media screen and (max-width: $bp-lg) {
                background: linear-gradient(180deg, #0076CA 0%, #005A9C 100%);
            }
            // padding-bottom: 104px;
            &::after{
                content: '';
                background: $natural-blue;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 104px;
                border-top-left-radius: 104px;
                @media screen and (max-width: $bp-lg) {
                    display: none;
                }
            }
            .bgvideo{
                object-fit: cover;
                width: 100%;
                border-bottom-right-radius: 362px;
                // min-height: 548px;
                // max-height: 548px;
                @media screen and (max-width: $bp-lg) {
                    border-bottom-right-radius: 161px;
                    height: 540px;
                }
                @media screen and (max-width: $bp-tablet) {
                    border-bottom-right-radius: 161px;
                    height: 340px;
                }
                
            }
            .img-slider{
                .slider-img{
                    border-radius: 0px;
                    border-bottom-right-radius: 362px;
                    width: 100%;
                    object-fit: cover;

                    @media screen and (max-width: $bp-lg) {
                        border-bottom-right-radius: 161px;
                        height: 540px;
                    }
                    @media screen and (max-width: $bp-tablet) {
                        border-bottom-right-radius: 161px;
                        height: 340px;
                    }
                }
               
            }
            
        }
        .hero-home-content{
            background: $color-white;
            padding: 24px 40px;
            padding-bottom: 10px;
            border-radius: 16px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            margin-bottom: 0px;
            @media screen and (max-width: $bp-lg) {
                padding: 24px;
            }
            h6{
                color: $middle-blue;
                font-size:14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                text-transform: uppercase;
                margin-bottom: 0px;
            }
            h1{
                color: $dark-grey;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 52px;
                margin-bottom: 0px;
                span{
                    background: linear-gradient(90deg, #0077CB -2.15%, #00A784 47.37%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                @media screen and (max-width: $bp-lg) {
                    font-size: 40px;
                    line-height: 48px;
                }
            }
            p{
                color: $dark-grey;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 0px;
            }
        }
        .pagination-arrow{
            background: $color-white;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            padding-left: 40px;
            padding-right: 40px;
            padding-bottom: 40px;
            padding-top: 30px;
            position: relative;
            display: flex;
            @media screen and (max-width: $bp-lg) {
                padding-bottom: 32px;
                padding-top: 0px;
                padding-left: 24px;
                padding-right: 24px;
            }
            .slick-dots{
                bottom: 0px;
                margin-top: 0px;
                text-align: left;
                position: unset;
                order: 1;
                width: auto;
                align-items: center;
                display: flex;
                z-index: 1; 
                margin-left: 15px;
                margin-right: 15px;
                li{
                    width: auto;
                    height: auto;
                    pointer-events: none;
                    button{
                        border-radius: 100px;
                        background: $neutral-gray;
                        transition: opacity 200ms ease;
                        width: 8px;
                        height: 8px;
                        &::before{
                        content: '';
                        }
                    }
                    &.slick-active{
                        button {
                            background: $dark-mid-green;
                            width: 32px;
                            // height: 30px;
                        }
                    }
                    &:hover {
                        span {
                            background: $dark-mid-green;
                        }
                    }
                }
            }
            .slick-arrow{
                position: unset;
                // bottom: 34px; /* Align arrows with dots at the bottom */
                z-index: 2; /* Ensure arrows are above dots */
                background-color: #fff; /* Optional: add a background color for visibility */
                border-radius: 50%;
                width: 30px; /* Adjust the size as needed */
                height: 30px;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                // top: 35%;
                transform: unset;
            }
            .slick-prev{
                z-index: 2;
                // left: 7%;
                width: auto;
                height: auto;
                // left: 40px;
                order: 0;
                // top: 28%;
                // transform: translate(0, -50%);
                &::before{
                    background: url(../images/Icon/hero-prev.svg);
                    height: 16px;
                    width: 16px;
                    content: '';
                    display: block;
                    background-repeat: no-repeat;
                    @media screen and (max-width: $bp-tablet) {
                        height: 16px;
                        width: 16px;
                        background-size: 100%;
                    }
                    // @media screen and (max-width: $bp-md) {
                    //     height: 42px;
                    //     width: 42px;
                    //     background-size: 100%;
                    // }
                }
                @media screen and (max-width: $bp-tablet) {
                    bottom: 2.2%;
                    top: auto;
                    right: auto;
                    left: auto;
                }
                @media screen and (min-width: $bp-tablet) and (max-width: $bp-lg) {
                    bottom: 4%;
                    top: auto;
                    right: 0;
                    left: 5%;
                }
            }
            .slick-next{
                z-index: 2;
                // right: 72%;
                width: auto;
                height: auto;
                order: 2;
                // right: 40px; 
                // top: 28%;
                // transform: translate(0, -50%);
                &::before{
                    background: url(../images/Icon/hero-next.svg);
                    height: 16px;
                    width: 16px;
                    content: '';
                    display: block;
                    background-repeat: no-repeat;
                    @media screen and (max-width: $bp-tablet) {
                        height: 16px;
                        width: 16px;
                        background-size: 100%;
                    }
                }
                @media screen and (max-width: $bp-tablet) {
                    bottom: 2.2%;
                    top: auto;
                    right: auto;
                    left: 38%;
                }
                @media screen and (min-width: $bp-md) and (max-width: $bp-lg){
                    bottom: 29px;
                    top: auto;
                    right: 4%;
                @media screen and (min-width: $bp-tablet) and (max-width: $bp-lg) {
                    right: 4%;
                    bottom: 4%;
                    top: auto;
                }
            }
            }
        }
    }
    .media-swoop{
        // background: linear-gradient(218deg, #0076CA 14.37%, #005A9C 64.51%);        ;
        position: absolute;
        right: 0;
        left: auto;
        // z-index: 1;
        top: 0;
        .desktop-img{
            width: 100%;
            height: 100% !important;
            border-bottom-right-radius: 104px !important;

            @media screen and (max-width: $bp-lg) {
                height: 540px !important;
                width: auto;
                display: none;
            }
            @media screen and (max-width: $bp-md) {
                height: 349px !important;
                width: auto;
                display: none;
            }
        }
        .mobile-img{
            display: none;
            border-bottom-right-radius: 104px !important;
            @media screen and (max-width: $bp-lg) {
                height: 540px !important;
                width: 100%;
                display: block;
            }
            @media screen and (max-width: $bp-tablet) {
                height: 349px !important;
                width: 100%;
                display: block;
            }
        }
    }
    
}
.hero-dropdown-main{
    padding-top: 40px;
    padding-bottom: 4px;
    @media screen and (max-width: $bp-lg) {
        padding-top: 16px;
    }
    .hero-dropdown-content{
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: $bp-lg) {
            align-items: normal;
            flex-direction: column;
            text-align: center;
            padding: 0px 16px;
        }
        .hero-dropdown-title{
            margin-right: 28px;
            color: $dark-grey;
            font-size:32px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            @media screen and (max-width: $bp-lg) {
                margin-bottom: 8px;
                font-size:28px;
                line-height: 36px;
            }
        }
        .hero-dd{
            .dropdown-menu{
                &.show{
                    width: 100%;
                    .dropdown-item{
                        font-size: 18px;
                        padding: 10px;
                        padding-left: 32px;
                        color: $dark-grey;
                        &:active{
                            background-color: $formal-blue !important;
                            color: $color-white !important;
                        }
                    }
                }
            }
            .dropdown-toggle{
                background: $color-white;
                border: 1px solid $neutral-gray;
                border-radius: 8px;
                padding: 24px 24px 24px 32px;
                color: $dark-grey;
                font-size:24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                min-width: 589px;
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-transform: capitalize;
                &::after{
                    // background: url(../images/Icon/select-arrow.svg) no-repeat 98% 50%;
                    border: none;
                    background-image: url("../images/Icon/arrow.svg");
                    line-height: 13px;
                    content: '';
                    background-size: 100%;
                    width: 40px;
                    height: 40px;
                    background-repeat: no-repeat;
                    @media screen and (max-width: $bp-lg) {
                        width: 24px;
                        height: 24px;
                    }
                }
                @media screen and (max-width: $bp-lg) {
                    min-width: 100%;
                    padding: 8px 16px;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}
.transparent{
    background-color: $transparent;
    // position: relative;
    // &::before{
    //     content: '';
    //     background: $natural-blue;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 64px;
    //     border-top-left-radius: 64px; 
    // }
}
.m-ngtv95{
    margin-top: -95px;
    position: relative;
}
.custom-blue{
    display: none;
    @media screen and (max-width: $bp-lg) {
        background-color: $custom-blue-hero-home;
        display: block;   
    }
}