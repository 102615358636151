body {
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.headline-1,
.headline-2,
.headline-3,
.headline-4,
.headline-5,
.headline-6 {
  margin-top: 0;
  margin-bottom: 2rem;
}

h1,
.headline-1 {
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 4.8rem;
}

h2,
.headline-2 {
  font-size: 4.8rem;
  font-weight: 300;
  line-height: 4rem;
}

h3,
.headline-3 {
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 3.4rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

h4,
.headline-4 {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 2.8rem;
  text-transform: uppercase;
}

h5,
.headline-5 {
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-transform: uppercase;
}

h6,
.headline-6 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.4rem;
}

p,
.text-1 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.text-2 {
  font-size: 1.2rem;
  line-height: 2rem;
}

caption,
figcaption,
.caption-1 {
  font-size: 1.3rem;
  line-height: 1.8rem;
}

.caption-2 {
  font-size: 1.1rem;
  line-height: 1.4rem;
}

a {
  font-weight: 700;
  text-decoration: underline;
}

strong,
.bold {
  font-weight: 700;
}
em,
.itallic {
  font-style: italic;
}

ul.content-ul {
  list-style: none;
  li {
    position: relative;
    padding-left: 2rem;
    &:before {
      content: '•';
      position: absolute;
      left: 0;
    }
  }
}
