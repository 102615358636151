.contactusMain{
    .contact-section{
        background: $natural-blue;
        padding: 40px 204px 24px;
        @media screen and (max-width: $bp-lg) {
            max-width: 100%;
            padding: 40px 16px 16px;
        }
        h2{
            color: $dark-grey;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px;
            margin-bottom: 8px;
            text-align: center;
            @media screen and (max-width: $bp-lg) {
                text-align: left;
            }
        }
        p{
            color: $color-black;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 8px;
            text-align: center;
            @media screen and (max-width: $bp-lg) {
                text-align: left;
            }
        }
        h6{
            color: $red-primary;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 8px;
            text-align: center;
            @media screen and (max-width: $bp-lg) {
                text-align: left;
            }
        }
    }
}
.contactusForm {
    max-width: 1200px;
    background: #ecf6fe;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 204px 16px;
    @media screen and (max-width: $bp-lg) {
        max-width: 100%;
        padding: 40px 16px 16px;
    }
    .validation-summary-errors{
        color: #B23427;
        font-size: 12px;
    }
    span.field-validation-error{
        color: #B23427;
        font-size: 12px;
    }
    .contact-info{
        margin-bottom: 16px;
        max-width: 486px;
        margin-left: auto;
        margin-right: auto;
        .contactInfo-title{
            color: $dark-grey;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            margin-bottom: 0px;
            // @media screen and (max-width: $bp-lg) {
            //     margin-bottom: 24px;
            // }
        }
        .contactformLabel{
            width: 100%;
            color: $dark-grey;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 4px;
            margin-top: 24px;
            text-transform: capitalize;
            &.mt-16{
                margin-top: 16px !important;
            }
        }
        .contactformInput{
            padding: 12px 8px;
            border-radius: 4px;
            border: 1px solid $neutral-gray;
            background: $color-white;
            // margin-bottom: 24px;
            width: 100%;
        }
        .contactformRadiotitle{
            width: 100%;
            color: $dark-grey;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 8px;
            margin-top: 24px;
        }
        .contactformRadio{
           margin-right: 24px;
            input{
                color: $dark-grey;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-right: 8px;
            }
        }
    }
    .contact-Help{
        // margin-bottom: 40px;
        max-width: 486px;
        margin-left: auto;
        margin-right: auto;
        @media screen and (max-width: $bp-lg) {
            padding: 0px 0px;
        }
        .contactInfo-title{
            color: $dark-grey;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            margin-bottom: 0px;
        }
        .contactformLabel{
            width: 100%;
            color: $dark-grey;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 4px;
            text-transform: capitalize;
            margin-top: 24px;
            &.mt-16{
                margin-top: 16px !important;
            }
        }
        .contactformInput{
            padding: 12px 8px;
            border-radius: 4px;
            border: 1px solid $neutral-gray;
            background: $color-white;
            // margin-bottom: 24px;
            width: 100%;
        }
        .contactformDD{
            padding: 12px 8px;
            border-radius: 4px;
            border: 1px solid $neutral-gray;
            background: $color-white;
            // margin-bottom: 24px;
            width: 100%;
            background: url(../images/Icon/contactformDD.svg) no-repeat 98% 50% $color-white;
            -webkit-appearance: none;
        }
    }
}