.video {
    &-section {
        width: 100%;
        margin-bottom: 40px;
    }

    &-wrapper {
        width: 100%;
        position: relative;
        .videoplayer{
            width: 100%;
            border-radius: 8px;
            overflow: hidden;
            padding-top: 56.25%;
            video, iframe{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
            }
            
            .controls{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 42px;
                height: 40px; 
            }
        }
    }
}