
    .spacing{
        height: 104px;
        @media screen and (max-width: $bp-md) {
            height: 64px;
        }
    }
    .heading-inner{
        padding: 40px 0px 0px ;
        text-align: center;
        .heading-small{
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
            text-transform: uppercase;
            color: $middle-blue;
            margin-bottom: 8px;
        }
        .heading-main{
            font-size:40px;
            font-style: normal;
            font-weight: 700;
            line-height:48px;
            color: $dark-grey;
            margin-bottom: 8px;
            @media screen and (max-width: $bp-md) {
                font-size: 32px;
                line-height: 40px;
            }
        }
        .heading-content{
            font-size:16px;
            font-style: normal;
            font-weight: 400;
            line-height:  24px;
            color: $dark-grey;
            margin-bottom: 0px;
        }
        @media screen and (max-width: $bp-sm-landscap) {
            padding: 24px 16.5px 0px;
        }
        @media screen and (max-width: $bp-md) {
            padding: 24px 16.5px 0px;
        }
    }
.green-color, .red-color{
    .heading-small, .heading-main, .heading-content{
        color: $color-white;
        text-align: center;
    }
}