.alert-banner {
  background-color: $background-green;
  display: none;
  &-warning {
    background-color: $red-primary;
  }
  .alert-banner-wrapper {
    max-width: 1200px;
    margin: auto;
    position: relative;
    @media screen and (min-width: 769px) and (max-width: 911px) {
      max-width: 690px;
    }
    .alert-banner-slide-count {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      position: absolute;
      color: $color-white;
      top: 50%;
      left: 18%;
      transform: translate(-16%, -50%);
      @media screen and (max-width: $bp-md) {
        top: 10px;
        left: 24px;
        transform: translate(0%, 0%);
      }
      @media screen and (min-width: 769px) and (max-width: 911px) {
        left: 2%;
      }
    }
  }

  &-content {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding: 0 10px 0 30px;
    height: auto;
    @media screen and (max-width: $bp-md) {
      justify-content: start;
      align-items: start;
      padding: 0 14px;
      min-height: 40px;
    }
    &-text-cta {
      display: flex;
      align-items: center;
      @media screen and (max-width: $bp-md) {
        flex-direction: column;
        align-items: start;
      }
    }

    p {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: $color-white;
      margin-bottom: 0;
    }
    &-icon,
    &-text {
      margin-right: 6px;
    }
    &-icon {
      max-width: 24px;
      @media screen and (max-width: $bp-md) {
        padding-top: 10px;
      }
      img {
        max-width: 15px;
        width: 15px;
        height: auto;
      }
    }
    &-text {
      padding: 10px 0;
      @media screen and (max-width: $bp-md) {
        padding: 10px 30px 0 0;
      }
    }
    &-close {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0%, -50%);
      @media screen and (max-width: $bp-md) {
        transform: translate(0%, 0%);
        top: 0;
        right: 2px;
      }
      @media screen and (min-width: 769px) and (max-width: 911px) {
        right: -8%;
      }
      a {
        width: 40px;
        height: 40px;
        display: inline-block;
        display: inline-block;
        text-align: center;
        padding-top: 6px;
        img {
          max-width: 25px;
        }
      }
    }
    &-cta {
      min-width: 70px;
      position: relative;
      top: -2px;
      white-space: nowrap;
      @media screen and (max-width: $bp-md) {
        padding-bottom: 10px;
      }
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $color-white;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &-item-wrapper {
    max-width: 792px;
    margin: auto;
    &.slick-initialized {
      .slick-slide {
        height: auto;
      }
    }
  }
  &-item {
    &.slick-slide {
      @media screen and (max-width: $bp-md) {
        padding-top: 25px;
      }
    }
  }
  .slick-next {
    &.slick-arrow {
      width: 24px;
      height: 24px;
      &::before {
        content: "";
        background-image: url(../images/Icon/arrow-alert-banner.svg);
        width: 12px;
        height: 12px;
        display: inline-block;
      }
      @media screen and (max-width: $bp-md) {
        right: auto;
        left: 40px;
        top: 18px;
      }
    }
  }
  .slick-prev {
    &.slick-arrow {
      width: 24px;
      height: 24px;
      left: -20px;
      &::before {
        content: "";
        background-image: url(../images/Icon/arrow-alert-banner.svg);
        width: 12px;
        height: 12px;
        display: inline-block;
        transform: rotate(180deg);
      }
      @media screen and (max-width: $bp-md) {
        left: 5px;
        top: 18px;
        z-index: 2;
      }
    }
  }
}
