.rte-component{
    padding: 40px 0px;
    @media screen and (max-width: $bp-lg) {
        padding: 24px 16.5px;
    }
    .rte-headings{
        h1{
            font-size:40px;
            font-style: normal;
            font-weight: 700;
            line-height:48px;
            color: $dark-grey;
            margin-bottom: 8px;
            @media screen and (max-width: $bp-md) {
                font-size: 32px;
                line-height: 40px;
            }
        }
        h2{
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            color: $dark-grey;
            text-transform: initial;
             margin-bottom: 16px;
             @media screen and (max-width: $bp-landscap) {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }
    .rte-inner{
        padding: 16px 24px;
        border-radius:8px;
        color: $dark-grey;
        @media screen and (max-width: $bp-lg) {
            img, video, iframe{
                width: 100% !important;
                height: auto !important;
            }
        }
        h3{
            font-weight: 700;
            font-size: 14px;
            margin-bottom: 8px;
            line-height: 20px;
            text-transform: initial;
        }
        h4{
            text-transform: initial;
        }
        p{
            font-weight: normal;
            font-size: 14px;
            margin-bottom: 8px;
            line-height: 20px;
        }
        ul,ol{
            font-weight: normal;
            font-size: 14px;
            margin-bottom: 10px;
            li{
                margin-bottom: 8px;
                line-height: 20px;
            }
        }
    }
}
.container-main{
     iframe{
        width: 100%;
    }
}