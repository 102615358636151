.auto-listing-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.auto-listing-container {
    margin: 20px 0px 40px 0px;

    @media screen and (max-width: 768px) {
        padding: 0px 7.5px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.auto-listing-item {
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #D4D5D6;
    background: #FFF;
    margin-bottom: 0px;
    overflow: hidden;
    color: #404040;


    .auto-listing-content {
        margin-top: 45px;

        @media screen and (max-width: $bp-lg) {
            margin-top: 8px;
        }
    }

    .auto-listing-img {

        position: relative;
        width: 100%;
        aspect-ratio: 4 / 3;

        .auto-listing-images {
            aspect-ratio: 4 / 3;

            img {
                width: 100%;
                height: 100%;
                border-radius: 8px 64px 8px 8px;
                background: #FFF;
                object-fit: cover;
            }

            .auto-listing-btn {
                .zoom-in-btn {
                    position: absolute;
                    bottom: 8px;
                    right: 8px;
                    border-style: none;
                    border-radius: 100%;
                    background-color: #FFF;
                    height: 32px;
                    width: 32px;

                    &::before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background: url("../images/icon/zoom-btn.svg") no-repeat center center;
                        z-index: 1;
                    }
                }
            }

            .content-slider {
                height: 100%;

                + {
                    .auto-listing-btn {
                        .zoom-in-btn {
                            @media screen and (max-width: $bp-lg) {
                                bottom: 52px;
                            }
                        }
                    }
                }
            }

            .slick-list {
                height: 100%;
            }

            .slick-track {
                height: 100%;
            }
        }

        .auto-listing-tag {
            position: absolute;
            top: 8px;
            left: 8px;
            background-color: #008674;
            color: white;
            padding: 3px 8px;
            border-radius: 4px;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-transform: uppercase;
            z-index: 1;
        }

        .pagination-arrow {
            background: $color-white;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            padding-left: 40px;
            padding-right: 40px;
            padding-bottom: 13px;
            padding-top: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 100%;
            top: 100%;

            @media screen and (max-width: $bp-lg) {
                padding-bottom: 15px;
                padding-top: 15px;
                padding-left: 24px;
                padding-right: 24px;
                position: relative;
                top: auto;
            }

            .slick-dots {
                bottom: 0px;
                margin-top: 0px;
                text-align: left;
                position: unset;
                order: 1;
                width: auto;
                align-items: center;
                display: flex;
                z-index: 1;
                margin-left: 15px;
                margin-right: 15px;

                li {
                    width: auto;
                    height: auto;
                    pointer-events: none;
                    margin: 0 2.5px;

                    button {
                        border-radius: 100px;
                        background: $neutral-gray;
                        transition: opacity 200ms ease;
                        width: 8px;
                        height: 8px;

                        &::before {
                            content: '';
                        }
                    }

                    &.slick-active {
                        button {
                            background: $dark-mid-green;
                            width: 32px;
                            // height: 30px;
                        }
                    }

                    &:hover {
                        span {
                            background: $dark-mid-green;
                        }
                    }
                }
            }

            .slick-arrow {
                position: unset;
                // bottom: 34px; /* Align arrows with dots at the bottom */
                z-index: 2;
                /* Ensure arrows are above dots */
                background-color: #fff;
                /* Optional: add a background color for visibility */
                border-radius: 50%;
                width: 30px;
                /* Adjust the size as needed */
                height: 30px;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                // top: 35%;
                transform: unset;
            }

            .slick-prev {
                z-index: 2;
                // left: 7%;
                width: auto;
                height: auto;
                // left: 40px;
                order: 0;

                // top: 28%;
                // transform: translate(0, -50%);
                &::before {
                    background: url(../images/Icon/hero-prev.svg);
                    height: 16px;
                    width: 16px;
                    content: '';
                    display: block;
                    background-repeat: no-repeat;

                    @media screen and (max-width: $bp-tablet) {
                        height: 16px;
                        width: 16px;
                        background-size: 100%;
                    }

                    // @media screen and (max-width: $bp-md) {
                    //     height: 42px;
                    //     width: 42px;
                    //     background-size: 100%;
                    // }
                }

                @media screen and (max-width: $bp-tablet) {
                    bottom: 2.2%;
                    top: auto;
                    right: auto;
                    left: auto;
                }

                @media screen and (min-width: $bp-tablet) and (max-width: $bp-lg) {
                    bottom: 4%;
                    top: auto;
                    right: 0;
                    left: 5%;
                }
            }

            .slick-next {
                z-index: 2;
                // right: 72%;
                width: auto;
                height: auto;
                order: 2;

                // right: 40px; 
                // top: 28%;
                // transform: translate(0, -50%);
                &::before {
                    background: url(../images/Icon/hero-next.svg);
                    height: 16px;
                    width: 16px;
                    content: '';
                    display: block;
                    background-repeat: no-repeat;

                    @media screen and (max-width: $bp-tablet) {
                        height: 16px;
                        width: 16px;
                        background-size: 100%;
                    }
                }

                @media screen and (max-width: $bp-tablet) {
                    bottom: 2.2%;
                    top: auto;
                    right: auto;
                    left: 38%;
                }

                @media screen and (min-width: $bp-md) and (max-width: $bp-lg) {
                    bottom: 29px;
                    top: auto;
                    right: 4%;

                    @media screen and (min-width: $bp-tablet) and (max-width: $bp-lg) {
                        right: 4%;
                        bottom: 4%;
                        top: auto;
                    }
                }
            }
        }
    }

    .auto-listing-content {
        // padding: 15px;

        .auto-listing-content-title {
            border-bottom: 1px solid #D4D5D6;
            padding-bottom: 5px;

            .sub-title {
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;
                font-weight: 700;
                margin: 0;
            }

            .price-title {
                font-size: 24px;
                line-height: 32px;
                color: #404040;
                margin: 5px 0 0px 0px;
                font-weight: 700;

                .cross {
                    text-decoration: line-through;
                    color: #404040;
                    font-size: 16px;
                    line-height: 24px;
                    margin-left: 5px;
                }
            }
        }

        .auto-listing-content-description {
            margin-top: 8px;

            ul {
                margin: 0px;


                li {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    color: #404040;
                    line-height: 24px;
                }
            }
        }
    }
}

.auto-listing-header {
    font-size: 14px;
    line-height: 20px;
    color: #404040;
    margin-left: 8px;
    margin-bottom: 16px;
    font-weight: 700;
    margin-top: 16px;

    span {
        color: #008674;
    }
}

// .radius-top-left-curve {
//     border-top-left-radius: 15px;
//     background-color: #e0e0e0;
// }



// Popup overlay
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .popup-content {
        background: #fff;
        padding: 20px;
        position: relative;
        max-width: 80%;
        max-height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .popup-img {
            max-width: 100%;
            height: auto;
            overflow: hidden;
        }

        .popup-details {
            margin-top: 15px;

            h3 {
                font-size: 24px;
                margin-bottom: 10px;
            }

            p {
                font-size: 16px;
                color: #666;
            }
        }

        .close-popup {
            position: absolute;
            top: 10px;
            right: 20px;
            font-size: 30px;
            color: black;
            cursor: pointer;
        }

        .slick-carousel {
            .slick-item {
                img {
                    max-width: 100%;
                    height: auto;
                }

                .popup-details {
                    margin-top: 15px;

                    h3 {
                        font-size: 24px;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 16px;
                        color: #666;
                    }
                }
            }
        }

        .slick-prev,
        .slick-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            border: none;
            padding: 10px;
            cursor: pointer;

            &.slick-prev {
                left: 10px;
            }

            &.slick-next {
                right: 10px;
            }
        }
    }
}

#paginationContainer {
    align-items: flex-start;
    display: flex;
    gap: 8px;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
        justify-content: center;
        margin-bottom: 20px;
    }

    button {
        background: #0000;
        border: none;
        border-radius: 50%;
        color: #042968;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: 700;
        height: 24px;
        line-height: 24px;
        padding: 0;
        width: 24px;
        &:disabled {
            opacity: 0.5;
            cursor: default;
        }

        &.active {
            background-color: #008674;
            color: #fff;
        }

        &.pagination-next {
            &:before {
                background: url(../images/Icon/hero-next.svg);
                height: 24px;
                width: 24px;
                content: "";
                display: block;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        &.pagination-prev {
            &:before {
                background: url(../images/Icon/hero-prev.svg);
                height: 24px;
                width: 24px;
                content: "";
                display: block;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

    }
}


// mgallery styling
.mgallery-wrapper {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;

    .mgallery {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 1200px;
        height: auto;
        max-height: 100vh;
        background-color: white;
        padding: 40px;
        color: #404040;

        @media screen and (max-width: 768px) {
            padding: 40px 24px;
            transform: none;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
        }

        .mgallery-controls {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0px;

            @media screen and (max-width: 768px) {
                flex-direction: column;
                gap: 12px;
                padding-left: 8px;
                padding-right: 8px;
            }
        }

        .mgallery-header {
            text-align: right;
            position: absolute;
            right: 40px;
            top: 0px;

            @media screen and (max-width: 768px) {
                right: 24px;
            }

            .close {
                font-size: 14px;
                cursor: pointer;
                line-height: 20px;
                padding: 10px 22px 10px 8px;
                display: block;
                position: relative;
                font-weight: 400;

                &::before {
                    content: "";
                    background-image: url(../images/Icon/close_small.svg);
                    width: 22px;
                    height: 22px;
                    display: block;
                    position: absolute;
                    right: 0px;
                    background-repeat: no-repeat;
                    background-position: center;
                    top: 9px;
                }
            }
        }

        .mgallery-content {
            margin-bottom: 16px;
            aspect-ratio: 16 / 9;
            object-fit: cover;
            display: flex;
            justify-content: center;
            overflow: hidden;
            border-radius: 8px;

            .lightbox-img {
                min-width: 100%;
                height: auto;
                display: block;
                /* margin: 0 auto; */
                object-fit: cover;
            }
        }

        .mgallery-controls {
            max-width: 100%;
            margin: 0 auto;

            .mgallery-title {
                font-size: 16px;
                line-height: 24px;
                margin: 0;
                font-weight: 700;
            }

            .mgallery-description {
                font-size: 14px;
                line-height: 20px;
                margin: 0;

                span {
                    text-decoration: line-through;
                    color: #404040;
                    font-size: 12px;
                    margin-left: 5px;
                }
            }



            .mgallery-caption {
                text-align: left;
                padding-right: 40px;

                @media screen and (max-width: 768px) {
                    padding-right: 0px;
                }
            }
        }

        .mgallery-footer {
            display: flex;
            gap: 24px;

            @media screen and (max-width: 768px) {
                justify-content: space-between;
            }

            .prev,
            .next {
                cursor: pointer;
                padding: 10px;
                width: 32px;
                height: 32px;
                border: 2px solid #042968;
                border-radius: 50px;
                position: relative;

                &::before {
                    content: "";
                    background-image: url(../images/Icon/angle-next.svg);
                    width: 28px;
                    height: 28px;
                    display: inline-block;
                    position: absolute;
                    left: 1px;
                    top: 0px;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            .prev {
                transform: rotate(180deg);
            }

        }

        span {
            font-size: 18px;
            font-weight: 700;
            line-height: 32px;

            @media screen and (max-width: 768px) {
                font-size: 16px;
            }

            &.mgallery-pagination {
                display: flex;

                >span {
                    width: 24px;
                    display: block;
                    text-align: center;
                }

                @media screen and (max-width: 768px) {
                    #current-index {
                        margin-right: 10px;
                    }

                    #total-count {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}