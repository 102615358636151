.news-article-title{
    margin-top: 40px;
    margin-bottom: 8px;
    h2{
        color:$dark-grey;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        @media screen and (max-width: $bp-lg) {
            line-height: 40px;
            font-size: 32px;
            margin-bottom: 8px;
        }
    }
    h6{
        color:$dark-grey;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        @media screen and (max-width: $bp-lg) {
            line-height: 20px;
            font-size: 16px;
            margin-bottom: 8px;
        }
    }
}