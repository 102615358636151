.btn-mdfl-primary{
    @include button($formal-blue, 4px, $color-white);
    padding: 12px 16px;
    text-align: center;
    font-family: "Noto Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
    transition: 0.8s;
    display: inline-block;
    margin-top: 16px;
    cursor: pointer;
    &:hover{
        @include button($bold-yellow, 4px, $formal-blue);
        transition: 0.8s;
    }
}
.btn-mdfl-primary-white{
    @include button($color-white, 4px, $formal-blue);
    padding: 12px 16px;
    text-align: center;
    font-family: "Noto Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
    transition: 0.8s;
    display: inline-block;
    margin-top: 16px;
    cursor: pointer;
    &:hover{
        @include button($bold-yellow, 4px, $formal-blue);
        transition: 0.8s;
    }
}
.btn-mdfl-secondary-white{
    @include button($transparent, 4px, $color-white);
    border: 2px solid $color-white;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-family: "Noto Sans";
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    display: inline-block;
    transition: 0.8s;
    margin-top: 16px;
    cursor: pointer;
    white-space: nowrap;
    &:hover{
        @include button($bold-yellow, 4px, $formal-blue);
        transition: 0.8s;
        border: 2px solid transparent;
    }
}
.btn-mdfl-secondary{
    @include button($color-white, 4px, $formal-blue);
    border: 2px solid $formal-blue;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    font-family: "Noto Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 16px;
    display: inline-block;
    transition: 0.8s;
    cursor: pointer;
    white-space: nowrap;
    &:hover{
        @include button($bold-yellow, 4px, $formal-blue);
        transition: 0.8s;
        border: 2px solid transparent;
    }
}
.btn-mdfl-secondary-sm{
    @include button($color-white, 4px, $formal-blue);
    border: 2px solid $formal-blue;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    padding: 7px 8px;
    font-size:12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    display: inline-block;
    transition: 0.8s;
    cursor: pointer;
    white-space: nowrap;
    &:hover{
        @include button($bold-yellow, 4px, $formal-blue);
        transition: 0.8s;
        border: 2px solid transparent;
    }
}
.btn-mdfl-primary-sm{
    @include button($formal-blue, 4px, $color-white);
    border: 2px solid $formal-blue;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    padding: 5px 8px;
    font-size:12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    display: inline-block;
    transition: 0.8s;
    cursor: pointer;
    &:hover{
        @include button($bold-yellow, 4px, $formal-blue);
        transition: 0.8s;
        border: 2px solid transparent;
    }
}
.mdfl-tartiary-white{
    @include button($transparent, 0px, $color-white);
    border: 0px solid $transparent;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: left;
    padding: 1px 0px;
    // display: block;
    transition: 0.1s;
    margin-top: 8px;
    position: relative;
    width: 100%;
    cursor: pointer;
    // white-space: nowrap;
    .btnarrow{
        position: relative;
        display: inline-block;
        padding: 4px 0;
        padding-left: 4px;
        &:before {
            content: "";
            display: block;
            width: 16px;
            height: 2px;
            background-color: $color-white;
            top: 50%;
            transition: width 0.5s ease-out;
          }
          &:after {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-top: 2px solid $color-white;
            border-right: 2px solid $color-white;
            transform: rotate(45deg);
            top: calc(50% - 4px);
            position: absolute;
            right: 1px;
          }
          
    }
    &:hover {
        @include button($transparent, 0px, $color-white);
        // transition: 0.8s;
        border-bottom: 2px solid $color-white;
        .btnarrow{
            cursor: pointer;
            &:before {
                width: 21px;
                background-color: $color-white;
            }
            &:after {
                border-color: $color-white;
            }
        }
      }
}
.mdfl-tartiary-primary{
    @include button($transparent, 0px, $formal-blue);
    border: 0px solid $transparent;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: left;
    padding: 1px 0px;
    display: inline;
    transition: 0.1s;
    margin-top: 8px;
    position: relative;
    cursor: pointer;
    // width: 100%;
    // white-space: nowrap;
    .btnarrow{
        position: relative;
        display: inline-block;
        padding: 4px 0;
        padding-left: 4px;
        &:before {
            content: "";
            display: block;
            width: 16px;
            height: 2px;
            background-color: $formal-blue;
            top: 50%;
            transition: width 0.5s ease-out;
          }
          &:after {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-top: 2px solid $formal-blue;
            border-right: 2px solid $formal-blue;
            transform: rotate(45deg);
            top: calc(50% - 4px);
            position: absolute;
            right: 1px;
          }
          
    }
    &:hover {
        @include button($transparent, 0px, $formal-blue);
        // transition: 0.8s;
        border-bottom: 2px solid $formal-blue;
        .btnarrow{
            cursor: pointer;
            &:before {
                width: 21px;
                background-color: $formal-blue;
            }
            &:after {
                border-color: $formal-blue;
            }
        }
      }
}