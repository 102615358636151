.mdfl-main-slider{
    @media screen and (max-width: $bp-lg) {
        padding-bottom: 24px;
    }
}
.mdfl-slider{
    width: 100%;
    display: flex;
    padding: 40px 0px;
    // &.mdfl-padding{
    //     padding: 0px 120px;

    // }
    @media screen and (max-width: $bp-lg) {
        padding: 24px 0px;
    }
    &.slick-dotted.slick-slider{
        margin-bottom: 0px;
    }
    .slick-dots{
        position: relative;
        bottom: 13px;
        li{
            width: auto;
            height: auto;
            pointer-events: none;
            button{
                border-radius: 100px;
                background: $neutral-gray;
                transition: opacity 200ms ease;
                width: 8px;
                height: 8px;
                &::before{
                content: '';
                }
            }
            &.slick-active{
                button {
                    background: $dark-mid-green;
                    width: 58px;
                    // height: 30px;
                }
            }
            &:hover {
                span {
                    background: $dark-mid-green;
                }
            }
        }
    }
    .slick-prev{
        z-index: 1;
        left: -5.5%;
        width: auto;
        height: auto;
        &::before{
            background: url(../images/left-arrow.svg);
            height: 40px;
            width: 40px;
            content: '';
            display: block;
            background-repeat: no-repeat;
            @media screen and (max-width: $bp-tablet) {
                height: 32px;
                width: 32px;
                background-size: 100%;
            }
            // @media screen and (max-width: $bp-md) {
            //     height: 42px;
            //     width: 42px;
            //     background-size: 100%;
            // }
        }
        @media screen and (max-width: $bp-tablet) {
            bottom: -48px;
            top: auto;
            right: 84px;
            left: auto;
        }
        @media screen and (min-width: $bp-tablet) and (max-width: $bp-lg) {
            bottom: 4%;
            top: auto;
            right: 0;
            left: 5%;
        }
    }
    .slick-next{
        z-index: 1;
        right: -5.5%;
        width: auto;
        height: auto;
        &::before{
            background: url(../images/right-arrow.svg);
            height: 40px;
            width: 40px;
            content: '';
            display: block;
            background-repeat: no-repeat;
            @media screen and (max-width: $bp-tablet) {
                height: 32px;
                width: 32px;
                background-size: 100%;
            }
        }
        @media screen and (max-width: $bp-tablet) {
            bottom: -48px;
            top: auto;
            right: 9%;
        }
        @media screen and (min-width: $bp-md) and (max-width: $bp-lg){
            bottom: 29px;
            top: auto;
            right: 4%;
        @media screen and (min-width: $bp-tablet) and (max-width: $bp-lg) {
            right: 4%;
            bottom: 4%;
            top: auto;
        }
    }
    }
    .slick-list{
        width: 100%;
        display: block;  
        // margin-bottom: 24px;
        padding-bottom: 40px;
        @media screen and (max-width: $bp-tablet) {
            width: 100%;
            padding: 0px 24px;
            padding-bottom: 0px;
        }
    }
    .mdfl-inner-slider{
        background-color: $color-white;
        border: 1px solid $neutral-gray;
        box-shadow: 0px 2px 8px 0px $natural-blue;
        border-radius: 12px;
        padding: 16px;
        // width: 23%;
        margin: 0 12px;
        display: inline-block;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media screen and (max-width: $bp-tablet) {
            width: 100% !important;
        }
        &:first-child{
            margin-left: 0px;
        }
        &:last-child{
            margin-right: 0px;
        }
        @media screen and (min-width: $bp-lg) {
            &:hover{
                &.box-hover{
                border: 2px solid  $background-green;
                box-shadow: 8px 8px 0px 0px $background-green;
                .img-card{
                    img{
                        transform: scale(1.2);
                    }
                }
                .btn-mdfl-primary{
                    @include button($bold-yellow, 4px, $formal-blue);
                    transition: 0.8s;
                }
                }
            }
        }
        
        .img-card{
            overflow: hidden;
            border-radius: 8px;
            border-top-right-radius: 64px;
            width: 100%;
            img{
                width: 100%;
                aspect-ratio: 12 / 9;
                transition: all .3s ease-in-out;
                object-fit: cover;
            }
            @media screen and (max-width: $bp-lg) {
                border-top-right-radius: 64px;
            }
        }
        
        .slider-content{
            padding: 24px 4px;
            .slider-span{
                color: $middle-blue;
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                text-transform: uppercase;
                margin-bottom: 4px;
            }
            h4.slider-heading{
                color: $dark-grey;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                text-transform: initial;
                margin-bottom: 8px;
            }
            .slider-text{
                color: $dark-grey;
                font-size:16px;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 0px;
            }
        }
        &.slick-slide {
            margin: 0px 12px;
            transition: all ease-in-out .3s;
            height: inherit;
            &:first{
                margin-left: 0px;
            }
            &:right{
                margin-right: 0px;
            }
            @media screen and (max-width: $bp-tablet) {
                margin: 0px 4px;
            }
            .img-card{
                overflow: hidden;
                border-radius: 8px;
                border-top-right-radius: 64px;
                width: 100%;
                img{
                    width: 100%;
                    aspect-ratio: 12 / 9;
                    transition: all .3s ease-in-out;
                    object-fit: cover;
                }
                @media screen and (max-width: $bp-lg) {
                    border-top-right-radius: 64px;
                }
            }
            .slider-content{
                padding: 24px 4px;
                .slider-span{
                    color: $middle-blue;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 20px;
                    text-transform: uppercase;
                    margin-bottom: 4px;
                }
                h4.slider-heading{
                    color: $dark-grey;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 32px;
                    text-transform: initial;
                    margin-bottom: 8px;
                }
                .slider-text{
                    color: $dark-grey;
                    font-size:16px;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 0px;
                }
            }
          }
    }
}
.mobile-custom-pagination{
    display: none;
    @media screen and (max-width: $bp-tablet) {
        display: block;
        position: relative;
        width: 100%;
        padding: 0px 9%;
        top: 2%;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: $dark-grey;
        // padding-bottom: 24px;
        height: 32px;
        align-items: center;
    }
    .current-slide, .number-slash, .total-slides{
        width: 24px;
        height: 24px;
        display: inline-block;
        position: relative;
        bottom: 0px;
        // left: 24px;
    }
}