.login-section{
    animation: fadeOut 3s;
    height: 100%;
    position: fixed;
    z-index: 10;
    &.login-sectionShow{
        animation: fadeIn 1s;
        // position: fixed;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        left: 0;
        right: 0;
        // height: 100%; 
        top: 0;
        bottom: 0;
        z-index: 10;
    }
    .login-main{
        // display: none;
        position: fixed;
        right:-1000px;
        // left: 0;
        // right: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: start;
        // transition: translate 2.5s ease-out;
        // translate: 45vw 0;
        overflow: hidden;
        text-align: left;
        top: 0%;
        height: 100%;
        padding: 40px;
        background: $color-white;
        z-index: 10;
        width: 503px;
        overflow-y: scroll;
        @media screen and (max-width: $bp-lg) {
            width: 50%;
            padding: 40px 16px;
        }
        @media screen and (max-width: $bp-tablet) {
            width: 100%;
            padding: 40px 16px;
        }
        
        &.login-show{
            // background: $color-white;
            // animation: slideLogin;
            // animation-duration: 2s;
            // transition: translate 1s ease-in;
            // translate: 0vw 0;
            // @keyframes slideLogin {
            //     from {
            //         translatex: 45vw 0;
            //     /*     scale: 200% 1; */
            //       }
                
            //       to {
            //         translatex: 0 0;
            //     /*     scale: 100% 1; */
            //       }
            //   }
        }
        .login-close{
            position: absolute;
            right: 2%;
            top: 1%;
        }
        .login-title{
            color: $dark-grey;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height:  32px;
            margin-bottom: 16px;
            text-transform: capitalize;
            text-align: left;
        }
        .login-tabs{
            border: 1px solid $neutral-gray;
            border-radius: 64px;
            padding: 4px;
            width: max-content;
            margin-bottom: 16px;
            ul{
                padding-left: 0px;
                margin-bottom: 0px;
                display: flex;
                gap: 8px;
                width: 100%;
                @media screen and (max-width: $bp-lg) {
                    gap: 0;
                }
                li{
                    color: $formal-blue;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    padding: 8px 16px;
                    display: inline-block;
                    border-radius:  24px;
                    &:hover{
                        background: $formal-blue;
                        color: $color-white;
                    }
                    &.active-login-tab{
                        border-radius:  24px;
                        background: $background-green;
                        color: $color-white;
                        &:hover{
                            background: $background-green;
                            color: $color-white;
                        }
                    }
                    @media screen and (max-width: $bp-lg) {
                        font-size: 14px;
                        line-height: 24px;
                        &.active-login-tab{
                            background: $background-green !important;
                            color: $color-white;
                            &:hover{
                                background: $transparent;
                                color: $color-white;
                            }
                        }
                        &:hover{
                            background: $transparent;
                            color: $formal-blue;
                        }
                    }
                }
            }
        }
        .login-frame{
            .login-form-title{
                display: flex;
                justify-content: space-between;
                p{
                    color: $dark-grey;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    margin-bottom: 4px;
                    span{
                        color: $red-primary;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        margin-bottom: 4px;
                    }
                }
                a{
                    color: $formal-blue;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    margin-bottom: 4px;
                }
            }
            .login-input{
                width: 100%;
                padding: 12px 8px;
                color: $dark-grey !important;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 16px;
                border: 1px solid $neutral-gray;
                border-radius: 4px;
            }
            .form-check{
                text-align: left;
                // margin-bottom: 16px;
                .form-check-input{
                    width: 20px;
                    height: 20px;
                }
                .form-check-label{
                    margin-left: 8px;
                    font-size: 12px;
                    line-height: 16px;
                    margin-top: 3px;
                }
                .form-check-input:checked {
                    background-color: #ffffff;
                    border-color: #078f0e;
                }
                .form-check-input:checked[type=checkbox] {
                    background-image: url('../images/Icon/green-check.svg');
                }
            }
            .login-enroll{
                color: $dark-grey;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height:  24px;
                margin-bottom: 0px;
                margin-top: 16px;
                text-transform: capitalize;
            }
            .btn-mdfl-secondary{
                margin-top: 8px;
            }
            .login-bottom-section{
                border-top: 1px solid $neutral-gray;
                padding-top: 16px;
                margin-top: 12%;
                h6{
                    color: $dark-grey;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height:  20px;
                    margin-bottom: 0px;
                    margin-top: 16px;
                    text-transform: uppercase;
                    margin-bottom: 16px;
                }
                .login-btn-grp{
                    margin-bottom: 16px;
                }
                .login-become-member{
                    background: $background-green;
                    color: $color-white;
                    border-radius: 8px;
                    padding: 16px;
                    margin-top: 22px;
                    h6{
                        color: $color-white;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height:  32px;
                        margin-bottom: 0px;
                        margin-top: 0px;
                        text-transform: none;
                        margin-bottom: 8px;
                    }
                    p{
                        color: $color-white;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height:  24px;
                        margin-bottom: 0px;
                        margin-top: 0px;
                        // text-transform: uppercase;
                        margin-bottom: 0px;
                    }
                    .btn-mdfl-primary-white{
                        margin-top: 8px;
                    }
                }
            }
        }
        .login-treasury{
            .login-form-title{
                display: flex;
                justify-content: space-between;
                p{
                    color: $dark-grey;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    margin-bottom: 4px;
                    span{
                        color: $red-primary;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        margin-bottom: 4px;
                    }
                }
                a{
                    color: $formal-blue;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    margin-bottom: 4px;
                }
            }
            .login-input{
                width: 100%;
                padding: 12px 8px;
                color: $dark-grey !important;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 16px;
                border: 1px solid $neutral-gray;
                border-radius: 4px;
            }
            .btn-mdfl-primary{
                margin-top: 0px;
            }
        }
        .btn-mdfl-primary{
            margin-right: 24px;
            min-width: 120px;
        }
        // .mdfl-tartiary-primary{
        //     min-width: 120px;
        // }
        .fade:not(.show) {
            opacity: 0;
            display: none !important;
        }
    }
}

@keyframes fadeIn {
0% { opacity: 0; }
100% { opacity: 1; }
}
@keyframes fadeOut {
0% { opacity: 1; }
100% { opacity: 0; }
}