.tabs-main{
    width: 100%;
    display: block;
    padding: 40px 0px 0px;
    @media screen and (max-width: $bp-lg) {
        padding:24px 16.5px 0px;
    }
    .tabs-section{
        display: flex;
        justify-content: center;
        ul{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background: $color-white;
            width: -moz-fit-content;
            width: fit-content;
            padding: 8px;
            border-radius: 64px;
            border: 1px solid $neutral-gray;
            margin-bottom: 0px;
            li{
                list-style: none;
                padding: 8px 16px;
                margin: 0px 4px;
                color: $formal-blue;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                text-transform: capitalize;
                border-radius: 24px;
                cursor: pointer;
                &:first-child{
                    margin-left: 0px;
                }
                &:last-child{
                    margin-right: 0px;
                }
                &.active-compare-tab{
                    background: $background-green;
                    color: $color-white;
                    &:hover{
                        background-color: $background-green;
                    }
                }
                &:hover{
                    background: $formal-blue;
                    color: $color-white;
                }
                @media screen and (max-width: $bp-lg) {
                    font-size: 14px;
                    line-height: 20px;
                }
                @media screen and (max-width: $bp-lg) {
                    display: none;
                }
            }
            
        }
    }
    .comparision-dropdown{
        display: none;
        padding: 16px 16px 16px 24px;
        // background:$color-white;
        border: 1px solid $neutral-gray;
        border-radius: 64px;
        width: 100%;
        background: url(../images/Icon/select-arrow.svg) no-repeat 97% 50% $color-white;
        -webkit-appearance: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $formal-blue;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        @media screen and (max-width: $bp-lg) {
            // display: block;
        }
    }
}
.comparision-table-section {
    &.fade:not(.show){
        display: none !important;
    }
    padding: 24px 0px 40px;
    @media screen and (max-width: $bp-lg) {
        padding: 24px 0px 24px 0px;
        &.mediasection-padding{
            padding: 24px 24px 0px;
        }
    } 
    .comparisionTable {
        // border: 1px solid $neutral-gray;
        // margin-bottom: 24px;
        border-radius: 8px;
        // .placeholder{
        //     width: 100%;
        // }
        .table-main-header {
            display: flex;
            width: 100%;
            z-index: 1000;
            transition: top 0.3s ease-in-out;
            // border-bottom: 1px solid $neutral-gray;
            // background: $color-white;
            .slick-list{
                width: 100%;
                padding: 0px 24px;
                .slick-track{
                    background: $color-white;
                    // margin-left: 24px;
                    // margin-right: 24px;
                }
            }
            &.comp-header-sticky{
                position: fixed;
                top: 0;
                z-index: 10;

                // width: 86.9%;
                // border-right: 1px solid $neutral-gray;
                @media screen and (max-width: $bp-lg) {
                    // width: 100%;
                    border-right: 0px;
                }
            }
            .table-head {
                width: 33.33%;
                padding: 16px;
                border: 1px solid $neutral-gray;
                border-right: 0px;
                text-align: center;
                flex: 1;
                background: $formal-blue;
                height: inherit;
                color: $color-white;
                &:last-child{
                    border-right: 1px solid $neutral-gray;
                    border-top-right-radius: 8px;
                }
                &:first-child{
                    border-top-left-radius: 8px;
                }
                .comparision-table-header{
                    .btn-mdfl-secondary{
                        margin-right: 5px;
                        padding: 12px 14px;
                        @media screen and (max-width: $bp-lg) {
                            margin-right: 0px;
                            margin-bottom: 16px;
                        }
                    }
                    .mdfl-tartiary-white{
                        white-space: nowrap;
                        .btnarrow{
                            margin-top: 16px;
                        }
                    }
                    p{
                        margin-bottom: 0px;
                        // color: $dark-grey;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        @media screen and (max-width: $bp-lg) {
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                    h6{
                        margin-bottom: 8px;
                        // color: $dark-grey;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px;
                        @media screen and (max-width: $bp-lg) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .table-body{
            width: 100%;
            .slick-list{
                width: 100%;
                padding: 0px 24px;
                &:last-child{
                    margin-bottom: 0px;
                    // @media screen and (max-width: $bp-lg) {
                    //     margin-bottom: 0px;
                    // }
                }
                .slick-track{
                    background: $color-white;
                    // margin-left: 24px;
                    // margin-right: 24px;
                }
            }
            .table-row-title{
                background: $mid-grey;
                padding: 8px;
                text-align: center;
                color: $dark-grey;
                font-size:18px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                border-left: 1px solid $neutral-gray;
                border-right: 1px solid $neutral-gray;
                @media screen and (max-width: $bp-lg) {
                    font-size: 16px;
                    // margin-left: 24px;
                }
                &.active-column {
                    @media screen and (max-width: $bp-lg) {
                        margin-left: 24px;
                        margin-right: 0px;
                        transition: all 0.1s ease;
                    }
                }
                
                &.inactive-column {
                    @media screen and (max-width: $bp-lg) {
                        margin-left: 0px;
                        margin-right: 24px;
                        transition: all 0.1s ease;
                    }
                }
            }
            .table-row-content{
                display: flex;
                // background: $color-white;
                &:last-child{
                    .table-col{
                        border-bottom:  1px solid $neutral-gray;
                        &:last-child{
                            border-right:  1px solid $neutral-gray;
                            border-bottom-right-radius: 8px;;
                        }
                        &:first-child{
                            border-bottom-left-radius: 8px;
                        }
                    }
                }
                &.slick-dotted.slick-slider{
                    margin-bottom: 0px;
                }
                .table-col{
                    width: 33.33%;
                    padding: 16px 7px;
                    text-align: center;
                    border: 1px solid $neutral-gray;
                    border-right:0px;
                    flex: 1;
                    border-top: 0px;
                    border-bottom: 0px;
                    height: auto;
                    background: $color-white;  
                    word-wrap: break-word;
                    @media screen and (max-width: $bp-lg) {
                        padding: 16px 9px;
                    }
                    &:last-child{
                        border-right:  1px solid $neutral-gray;
                    }
                    p{
                        margin-bottom: 0px;
                        color: $dark-grey;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                    }
                    a{
                        color: $formal-blue;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        text-decoration-line: underline;
                    }
                    img{
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }
}
.pagination-sticky {
    //     margin: 24px 0px 0px;
    //     padding: 0px 0px;
        position: relative;
        height: 41px;
        display: none;
        margin-top: 0px;
        background-color: $formal-blue;
        // top: 222px;
        &.stickypagination-scroll{
            position: fixed;
            // width: 96.3%;
            margin: 0px;
            z-index: 1;
            padding: 4px 0px;
        }
        @media screen and (max-width: $bp-lg) {
            display: block;
        }
        .slick-arrow{
            &.slick-disabled{
                pointer-events: none;
            }
        }
        .slick-dots{
            bottom: 10px;
            @media screen and (max-width: $bp-lg) {
                bottom: auto;
            }
            li{
                height: 8px;
                width: 8px;
                button{
                    background: $color-white;
                    width: 8px;
                    height: 8px;
                    border-radius: 40px;
                    transition: opacity 400ms ease;
                    &::before{
                        display: none;
                    }
                }
            }
            li.slick-active {
                width: 32px;
                height: 8px;
                button {
                    width: 32px;
                    height: 8px;
                    background: $color-white;
                    margin-right: 0px;
                }
            }   
        }
        .slick-prev{
            z-index: 1;
            left: 8px;
            width: 32px;
            height: 32px;
            bottom: 0%;
            top: 48%;
            &::before{
                background: url(../images/left-arrow.svg);
                height: 32px;
                width: 32px;
                content: '';
                display: block;
                background-size: 100%;
            }
        }
        .slick-next{
            z-index: 1;
            right: 8px;
            width: 32px;
            height: 32px;
            bottom: 0%;
            top: 48%;
            &::before{
                background: url(../images/right-arrow.svg);
                height: 32px;
                width: 32px;
                content: '';
                display: block;
                background-size: 100%;
            }
        }
    }
