// .accordion-main{
   
    .accordion{
        padding: 0px 0px 40px;
        display: block;
        width: 100%;
        @media screen and (max-width: $bp-lg) {
            padding: 0px 16.5px 8px;
        }
        // margin-bottom: 80px;
        &:last-child{
            margin-bottom: 0px;
        }
        .accordion-section{
            text-align: center;
            padding: 40px 5px 24px;
            display: block;
            width: 100%;
            border-bottom: 1px solid $neutral-gray;
            @media screen and (max-width: $bp-lg) {
                padding: 24px 0px 16px;
                
                .btn-mdfl-secondary{
                    margin-bottom: 64px;
                }
            }

            // margin-bottom: 24px;
            // &:first-child{
            //     margin-top: 50px;
            // }
            h2{
                color: $dark-grey;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height:48px;
                margin-bottom: 8px;
                max-width: 786px;
                margin-left: auto;
                margin-right: auto;
                @media screen and (max-width: $bp-lg) {
                    font-size: 32px;
                    line-height: 40px;
                }
            }
            p{
                color: $dark-grey;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height:  24px; /* 150% */
                margin-bottom: 0px;
                max-width: 786px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .accordion-item{
            border: 0px;
            
            .accordion-button{
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                color: $dark-grey;
                padding: 16px;
                // border-top: 1px solid $neutral-gray;
                background-color: $color-white;
                border-radius: 0px;
                &:last-child{
                    border-bottom: 1px solid $neutral-gray;
                    border-radius: 0px;
                }
                &::after{
                    background-image: url('../images/Icon/arrow.svg');
                    width: 4rem;
                    height: 4rem;
                    background-size: 100%;        
                }
                &:not(.collapsed){
                    background-color: $color-white;
                    border-radius: 0px;
                    box-shadow:none;
                    color: $formal-blue;
                }
                &:focus{
                    box-shadow: none;
                }
                span{
                    border-bottom: 2px solid $transparent;
                    transition: 0.5s;
                    // &:hover{
                    //     border-bottom: 3px solid $formal-blue;
                    // }
                }
                &:hover{
                    span{
                        color: $formal-blue;
                        border-bottom: 2px solid $formal-blue;
                        @media screen and (max-width: $bp-lg) {
                            color: $dark-grey;
                            border-bottom: 0px;
                        }
                    }
                }
                @media screen and (max-width: $bp-lg) {
                    font-size: 16px;
                    line-height: 20px;
                    &::after{
                        width: 32px;
                        height: 32px;
                    }
                }
            }
            .accordion-collapse{
                .accordion-body{
                    background: $light-grey;
                    padding: 40px 16px;
                    @media screen and (max-width: $bp-lg) {
                        padding: 24px 16px;
                    }
                    a,a:not([href]) {
                        color: $formal-blue;
                        text-decoration: underline;
                        font-weight: 400;
                    }
                    h5{
                        color:$dark-grey;
                        font-size:18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px;
                        margin-bottom: 8px;
                        text-transform: capitalize;
                    }
                    p{
                        color:$dark-grey;
                        font-size:16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                    }
                    img{
                        margin-bottom: 24px;
                        width: auto;
                        border-radius: 8px;
                        @media screen and (max-width: $bp-lg) {
                            width: 100% !important;
                            height: auto !important;
                        }
                    }
                    ul{
                        margin-bottom: 24px;
                        li{
                            color:$dark-grey;
                            font-size:16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                        }
                    }
                    td{
                        vertical-align: top;
                        min-width: 282px;
                        background: #fff;
                        @media screen and (max-width: $bp-lg) {
                            min-width: 122px;  
                        }
                    }
                }
            }
            @media screen and (max-width: $bp-lg) {
                .table-wrapper{
                    padding-left: 0px;
                    >{
                        .container{
                            padding: 0px;
                        }
                    }
                }
                .table-section{
                    padding-top: 0px;
                    
                    .table-container{
                        margin-top: 24px;
                    }
                    .button-container{
                        padding-right: 0px;
                    }
                }
            }
        }
        
    }
// }
// .topspace{
//     padding-top: 94px;
// }