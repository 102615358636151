@font-face {
  font-family: "Noto Sans";
  src: url("../fonts/NotoSans-Regular.eot");
  src: url("../fonts/NotoSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NotoSans-Regular.woff2") format("woff2"),
    url("../fonts/NotoSans-Regular.woff") format("woff"),
    url("../fonts/NotoSans-Regular.ttf") format("truetype"),
    url("../fonts/NotoSans-Regular.svg#NotoSans-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../fonts/NotoSans-Italic.eot");
  src: local("Noto Sans Italic"), local("NotoSans-Italic"),
    url("../fonts/NotoSans-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NotoSans-Italic.woff2") format("woff2"),
    url("../fonts/NotoSans-Italic.woff") format("woff"),
    url("../fonts/NotoSans-Italic.ttf") format("truetype"),
    url("../fonts/NotoSans-Italic.svg#NotoSans-Italic") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../fonts/NotoSans-Bold.eot");
  src: local("Noto Sans Bold"), local("NotoSans-Bold"),
    url("../fonts/NotoSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NotoSans-Bold.woff2") format("woff2"),
    url("../fonts/NotoSans-Bold.woff") format("woff"),
    url("../fonts/NotoSans-Bold.ttf") format("truetype"),
    url("../fonts/NotoSans-Bold.svg#NotoSans-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../fonts/NotoSans-BoldItalic.eot");
  src: local("Noto Sans BoldItalic"), local("NotoSans-BoldItalic"),
    url("../fonts/NotoSans-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NotoSans-BoldItalic.woff2") format("woff2"),
    url("../fonts/NotoSans-BoldItalic.woff") format("woff"),
    url("../fonts/NotoSans-BoldItalic.ttf") format("truetype"),
    url("../fonts/NotoSans-BoldItalic.svg#NotoSans-BoldItalic") format("svg");
  font-weight: 700;
  font-style: italic;
}
