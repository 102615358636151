:root{
    --bs-body-font-family:'Noto Sans'
}
.container-main{
    overflow: hidden;
}
// .container-fluid{
//     padding: 0px !important;
//     overflow: hidden;
// }
.radius-top-left-curve{
    border-top-left-radius: 104px;
    @media screen and (max-width: $bp-lg){
        border-top-left-radius: 64px;
    }
}
.radius-bottom-right-curve{
    border-bottom-right-radius: 104px;
    @media screen and (max-width: $bp-lg){
        border-bottom-right-radius: 64px;
    }
}
.bright-blue{
    background-color: $bright-blue;
}
.blue-color{
    background-color: $middle-blue;
}
.natural-blue{
    background-color: $natural-blue;
}
.white-color{
    background-color: $color-white;
}
.green-color{
    background-color: $background-green;
}
.red-color{
    background-color: $red-primary;
}
.bright-green{
    background-color: $bright-green;
}
.bright-teal{
    background-color: $bright-teal;
}
.h-104{
    height: 104px;
}
.mr-16{
    margin-right: 16px;
}
.grey-color{
    background-color: $light-grey;
}
.mb-79{
    margin-bottom: 70px;
    @media screen and (max-width: $bp-md) {
        margin-bottom: 40px;
    }
}
.fixed-div {
    position: fixed;
    top: 0;
    // background: lightcoral;
    // width: 100%;
    // height: 50px;
    border: 1px solid red;
}
.static-div {
    position: static !important; /* Default position */
    background: lightblue;
    // width: 100%;
    // height: 50px;
    border: 1px solid blue;
}
.sticky-div {
    position: fixed !important;
    top: 0;
    z-index: 1000;
    background: lightcoral;
}
.container{
    @media screen and (min-width: $bp-xl) {  
        max-width: 1200px;
    }
    // @media screen and (max-width: $bp-md) {
    //     // padding: 0px;
    // }
}