.tiles{
    padding: 40px 0px;
    @media screen and (max-width: $bp-lg) {
        padding: 24px;
        padding-left: 40px;
    }
    .row{
        &:last-child{
            .col-lg-3{
                &:last-child{
                    .tile-section{
                        @media screen and (max-width: $bp-tablet) {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }
    .col-lg-3{
        // padding: 0px 12px;
        flex: 1;
            @media screen and (max-width: $bp-tablet) {
                padding: 0px 7.5px;
                flex: auto;
                padding-right:0px;
            }
        
    }
    .tile-section{
        border: 1px solid $neutral-gray;
        border-radius: 8px;
        padding-top: 40px;
        padding-bottom: 24px;
        padding-left: 24px;
        padding-right: 24px;
        text-align: center;
        position: relative;
        transition: 0.8s;
        width: 100%;
        margin-top: 24px;
        margin-bottom: 8px;
        background-color: white;
        @media screen and (max-width: $bp-tablet){
            margin-bottom: 8px;
            text-align: left;
            padding-top: 16px;
            padding-bottom: 16px;
            padding-right: 16px;
            padding-left: 40px;
            display: flex;
            align-items: center;
            margin-top: 0px;
        }
        @media screen and (min-width: $bp-tablet) and (max-width: $bp-lg) {
            margin: 4% 0%;
        }
        &.tiles-border{
            border-top: 4px solid $background-green;
            @media screen and (max-width: $bp-tablet) {
                border-top:1px solid $neutral-gray;
                border-left: 4px solid $background-green;
            }
        }
        &.tiles-hover{
           &:hover{
                box-shadow: 8px 8px 0px 0px $background-green;
                border: 1px solid $background-green;
                transition: 0.8s;
           }
        }
        .tile-icon{
            position: absolute;
            width: 100%;
            top: -31px;
            left: 0%;
            right: 0%;   
            img{
                width: 60px;
                height: 60px;
            }
            @media screen and (max-width: $bp-tablet) {
                display: flex;
                justify-content: flex-start;
                margin-right: 20px;
                align-items: center;
                top: auto;
                left: -10%;
            }     
        }
        .tile-desctiption{
            // text-align: center;
            .mdfl-tartiary-primary{
                margin-top: 8px;
                display: inline-block;
                text-align: center;
            }
            .tile-title{
                color:$dark-grey;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height:24px;
                margin-bottom: 8px;
                text-transform: capitalize;
                @media screen and (max-width: $bp-lg) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .tile-text{
                color: $dark-grey;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 0px;
                line-height: 20px;
            }
        }
        

    }
}