.primary-blue-gradiant{
    background: linear-gradient(132deg,$middle-dark-blue -5.09%,$middle-blue 56.56%);  
    @media screen and (max-width: $bp-md){
        background: linear-gradient(349deg, $true-blue -4.72%, $middle-blue 25.34%);
    }
// background: linear-gradient(40deg, #0076CA 0.98% 0%, #00518A 17.91%, #00518A 67.91%, hsl(204.94, 100%, 41.61%) 83%, #005A9C 100.56%);
    .swoosh-boundries{
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        max-width: 1500px;
        left: 50%;
        transform: translateX(-39%); 
        
        .ellipse-top{
            width: 394px;
            height: 394px;
            position: absolute;
            right: 249px;
            top: -30px;
            border-radius: 414px;
            background-color: $true-blue;
            filter: blur(75px);
            @media screen  and (min-width:1215px) and (max-width:1570px){
                right: 277px;
                top: -63px;
            }
            @media screen  and (min-width:768px) and (max-width:991px){
                right: 0px;
                top: -30px;
            }
            @media screen and (max-width: $bp-md){
                display: block;
                left: auto;
                top: -140px;
                right: 0;
                filter: blur(50px);
            }
            @media screen and (max-width: 768px){
                top: -50px;
            }
        }
        @media screen and (max-width: 768px){
            height: 360px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
        }
        .swoosh{
            // background: url("../images/blueswoop.png") no-repeat;
            // background-size: 100% 100%;
            // position: absolute;
            // display: block;
            // width: 100%;
            // height: 100%;
            // max-width: 1500px;
            // // left: 50%;
            // // transform: translateX(-29%);
            // @media screen and (max-width: $bp-sm-landscap){
            //     display: none;
            // }
        }
        &::after{
            // content: url('../images/blueswoop.svg');
            background-image: url('../images/blueswoop.png') ;
            background-size: 100% 100%;
            display: inline-block;
            content: '';
            width: 98%;
            height: 100%;
            position: absolute;
            background-repeat: no-repeat;
            @media screen  and (min-width:992px) and (max-width:1430px){
                background-image: url('../images/blueswoop.png') ;
                background-size: 103% 100%;
            }
            @media screen  and (min-width:1215px) and (max-width:1570px){
                width: 96%;
            }
            @media screen  and (max-width:991px){
                background: url(../images/Swoop-mobile.png) no-repeat right top;
                background-size: 100% auto !important;
                width: 100%;
            }
        }
        ~ {
            .hero-main{
                @media screen and (max-width: $bp-md){
                    &::before{
                        content: "";
                        width: 394px;
                        height: 394px;
                        position: absolute;
                        right: -150px;
                        top: -100px;
                        background-color: #0076CA;
                        filter: blur(75px);
                    }
                }
            }
        }
    }
    .ellipse-bottom{
        width: 394px;
        height: 394px;
        position: absolute;
        left: -141px;
        bottom: -232px;
        border-radius: 414px;
        background-color: $true-blue;
        filter: blur(75px);
        @media screen and (max-width: $bp-md){
            display: block;
            left: -179px;
            // bottom: -42.881px;
        }
    }
}
.primary-teal-gradiant{
    background: linear-gradient(132deg, $dark-teal -5.09%, $light-teal 56.56%);  
    @media screen and (max-width: $bp-md){
        background: linear-gradient(349deg, $dark-teal -4.72%, $light-teal 25.34%);
    }
// background: linear-gradient(40deg, #0076CA 0.98% 0%, #00518A 17.91%, #00518A 67.91%, hsl(204.94, 100%, 41.61%) 83%, #005A9C 100.56%);
.swoosh-boundries{
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    max-width: 1500px;
    left: 50%;
    transform: translateX(-39%); 
   
    .ellipse-top{
        width: 394px;
        height: 394px;
        position: absolute;
        right: 49px;
        top: -30px;
        border-radius: 414px;
        background-color: $bright-teal;
        filter: blur(75px);
        @media screen and (max-width: $bp-md){
            display: block;
            left: auto;
            top: -219.642px;
            right: 0;
            filter: blur(50px);
        }
    }
    // .swoosh{
    //     background: url("../images/teal-swoop.svg") no-repeat;
    //     background-size: 100% 100%;
    //     position: absolute;
    //     display: block;
    //     width: 100%;
    //     height: 100%;
    //     max-width: 1500px;
    //         left: 50%;
    //         transform: translateX(-42%);
    //     @media screen and (max-width: $bp-sm-landscap){
    //         display: none;
    //     }
    // }
    &::after{
        // content: url('../images/blueswoop.svg');
        background-image: url('../images/teal-swoop.svg') ;
            background-size: 100% 100%;
            display: inline-block;
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            @media screen  and (max-width:$bp-lg){
                background-size: 100% 30%;
                background-repeat: no-repeat;
                background-image: none;
            }
            // @media screen and (max-width:$bp-md) {
            //     display: none;
            // }
    }
}
.ellipse-bottom{
    width: 394px;
    height: 394px;
    position: absolute;
    left: -141px;
    bottom: -232px;
    border-radius: 414px;
    background-color: $bright-teal;
    filter: blur(75px);
    @media screen and (max-width: $bp-md){
        display: block;
        left: -179px;
        bottom: -42.881px;
    }
}
}
.hero-content{
    position: relative;
    overflow: hidden;
    > div{
        overflow: hidden;
        z-index: 0;
        position: relative;
    }
    .primary-blue-gradiant{
        .hero-inner{
            // @media screen and (min-width:$bp-xs) and (max-width: $bp-sm) {
            //     background: url(../images/Swoop-mobile.png) no-repeat left top;
            //     background-size: 100%;
            //     flex-direction: column;
            // }
            // @media screen and (max-width: $bp-sm-landscap){
            //     background: url(../images/Swoop-mobile.png) no-repeat left top;
            //     background-size: 100% 89%;
            // }
        }
    }
    .primary-teal-gradiant{
        .hero-inner{
            @media screen and (max-width: $bp-md) {
                background: url(../images/mobile-teal-swoop.svg) no-repeat left top;
                background-size: 100%;
                flex-direction: column;
            }
            @media screen and (max-width: $bp-md){
                background: url(../images/mobile-teal-swoop.svg) no-repeat left top;
                background-size: 100%;
            }
        }
    }
    .hero-main{
        color: $color-white;
        overflow: hidden;
        padding: 64px 0px 64px 0px;
        .hero-inner{
            display: flex;
            align-items: center;
            position: relative;
            z-index: 1;
            
            .hero-left-text{
                // margin-right: 64px;
                // width: 50%;
                padding: 0px 25px 0px 0px;
                .hero-span{
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                    display: block;
                    line-height: 20px;
                }
                .hero-main-title{
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 52px;
                    text-transform: capitalize;
                }
                .hero-text{
                    font-size: 16px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 24px;
                    margin-bottom: 8px;
                }
                @media screen and (max-width: $bp-md) {
                    .hero-main-title{
                        font-size: 40px;
                        line-height: 48px;
                    }
                    .hero-text{
                        margin-bottom: 8px;
                    }
                }
            }
            .hero-media{
                // width: 50%;
                // padding: 64px 0px 64px 0px;
                position: relative;
                // @media screen and (max-width: $bp-landscap) {
                //     padding: 64px 64px 64px 0px;
                // }
                .hero-banner{
                    position: relative;
                    width: auto;
                    max-width: 586px;
                    .hero-banner-image{
                        // min-width: 586px;
                        // max-width: 586px;
                        width: 100%;
                        border-radius: 16px;
                        // height: 100%;
                        // max-height: 420px;
                        // min-height: 420px;
                        object-fit: cover;
                        @media screen and (min-width: $bp-sm-landscap) and (max-width: $bp-lg) {
                            // max-width: min-content !important;
                            // min-width: auto !important;
                        }
                    }
                    .img-overlay {
                        position: absolute;
                        height: 187px;
                        width: 310px;
                        top: 50%;
                        right: 0%;
                        object-fit: cover;
                        border-radius: 6.76px;
                    }
                }
                .videoplayer{
                    position: relative;
                    max-width: 587px;
                    @media screen and (max-width: $bp-tablet) {
                        max-width: 308px;
                        min-height: 231px;
                    }
                    @media screen and (max-width: $bp-xs) {
                        max-width: 287px;
                    }
                    video{
                        max-width: 586px;
                        width: 100%;
                        max-height: 420px;
                        height: 420px;
                        // max-height: 420px;
                        // min-height: 420px;
                        border-radius: 16px;
                        object-fit: cover;
                        aspect-ratio: 16 / 9;
                        @media screen and (max-width: $bp-tablet) {
                            max-height: 231px;
                            height: 231px;
                        }
                        @media screen and (max-width: $bp-md) {
                            max-height: none;
                        }
                    }
                    #controls {
                        position: absolute;
                        right: 2%;
                        bottom: 3%;
                        
                        .pause-video{
                            max-width: inherit;
                            border-radius: 0px;
                            width: auto;
                            
                        }
                        .play-video{
                            max-width: inherit;
                            border-radius: 0px;
                            width: auto;
                            display: none;
                        }
                    }
                    iframe{
                        border-radius: 10px;
                        width: 100%;
                        .player{
                            border-radius: 10px !important;
                        }
                        @media screen and (max-width: $bp-tablet) {
                            height: 231px;
                        }
                    }
                }
                .hero-right-lists{
                    .detail-list{
                        ul{
                            padding-left: 0px;
                            @media screen and (max-width: $bp-landscap) {
                                margin-bottom: 10px;
                            }
                            li{
                                background-color: $middle-dark-blue;
                                margin-bottom: 24px;
                                padding: 16px;
                                border-radius: 8px;
                                list-style: none;
                                position: relative;
                                left: 0%;
                                padding-left: 55px;
                                left: 0%;
                                font-size: 18px;
                                font-weight: 700;
                                @media screen and (max-width: $bp-tablet) {
                                    margin-bottom: 8px;
                                    font-size: 16px;
                                }
                                &::before{
                                    content:url('../images/check.svg');
                                    position: absolute;
                                    top: 28%;
                                    margin-right: 16px;
                                    left: 2%;
                                    @media screen and (max-width: $bp-md) {
                                        left: 4%;
                                    }
                                    @media screen and (min-width: $bp-lg) and (max-width: $bp-xxl) {
                                        left: 3%;
                                    }
                                }
                            }
                        }
                    }
                }
                // .shadow-box{
                //     @include box-shadow(16px, 16px, 0px, $middle-dark-blue)
                // }
            }
            @media screen and (max-width: $bp-xl) {
                .hero-media{
                    .hero-banner{
                        .hero-banner-image{
                            width: 100% !important;
                            min-height: 100%;
                            // max-width: 350px !important;
                        }
                        .img-overlay{
                            width: 58% !important;
                            top: 45%;
                            right: 0%;
                            height: 50%;
                            border-radius: 6.76px;
                        }
                    }
                }
            }
           
            @media screen and (max-width: $bp-sm-landscap) {
                .hero-left-text{
                    margin-right: 0px;
                    padding: 40px 16.5px 24px;
                    width: 100%;
                }
                .hero-media{
                    padding:  0px 16.5px 40px;
                    width: 100%;
                    .hero-banner{
                        .hero-banner-image{
                            width: 90% !important;
                            min-height: 100%;
                            // max-width: 350px !important;
                        }
                        .img-overlay{
                            width: 58% !important;
                            top: 45%;
                            right: 0%;
                            height: 50%;
                            border-radius: 3.95px;
                        }
                    }
                }
            }
            @media screen and (max-width: $bp-landscap) {
                .hero-left-text{
                    padding: 40px 16.5px;   
                    margin-right: 0px;
                }
                .hero-media{
                    padding: 40px 16.5px;
                }
            }
            
            @media screen and (max-width: $bp-md) {
                .hero-left-text{
                    width: 100% !important;
                    margin-right: 0px;
                    padding: 40px 16.5px 24px;
                }
                .hero-media{
                    width: 100%;
                    padding: 0px 16.5px 40px;
                    height: 100%;
                    .hero-banner{
                        .hero-banner-image{
                            width: 90% !important;
                            min-width: 90% !important;
                            max-width: 90% !important;
                            min-height: 100%;
                        }
                        .img-overlay{
                            width: 58% !important;
                            top: 45%;
                            right: 0%;
                            height: 50%;
                            border-radius: 3.95px;
                        }
                    }
                    .videoplayer{
                        video{
                            max-width: none;
                        }
                        #controls {
                            right: 3%;
                            bottom: 4%;
                        }
                    }
                }
            }
            
        }
        @media screen and (max-width: $bp-md) {
            padding: 0px;
            .hero-inner{
                flex-direction: column;
                .videoplayer{
                    video{
                        max-width: none;
                    }
                    #controls {
                        right: 5%;
                        bottom: 13%;
                    }
                }
            }
        }
    }
    @media screen and (max-width: $bp-md) {
        .hero-main{
            border-bottom-right-radius: 64px;
        }
    }
}
.sky-blue{
    background-color: lightskyblue;
}