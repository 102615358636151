$color-black:#000000;
$color-white:#ffffff;
$green-primary: #02947f;
$red-primary:#B23427;
$blue-primary:#005b9c;
$formal-blue: #042968;
$true-blue: #0076CA;
$middle-blue:#005A9C;
$background-green:#008674;
$light-green: #00B49C;
$middle-dark-blue: #00518A;
$dark-grey: #404040;
$natural-blue: #ECF6FE;
$transparent: transparent;
$bold-yellow: #FFC900;
$bright-blue: #0073c6;
$mid-green: #007C6C;
$light-grey: #F4F4F4;
$neutral-gray: #D4D5D6;
$dark-mid-green: #00937F;
$mid-grey: #E7E8E9;
$mid-dark-green: #00685A;
$bright-green: #00B49C;
$custom-blue-hero-home: #0074c9;
$dark-teal: #124154;
$light-teal: #00708A;
$soft-teal: #0892AF;
$bright-teal: #088fab;
// Break-points
$bp-xs: 320px;
$bp-xs-sm: 390px;
$bp-sm: 576px;
$bp-tablet: 650px;
$bp-md: 768px;
$bp-sm-landscap: 950px;
$bp-landscap: 992px;
$bp-lg: 1024px;
$bp-xl: 1200px;
$bp-xxl: 1440px;
$bp-xxxl: 1680px;