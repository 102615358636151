footer {
    background-color: #fff;
    color: #404040;
    padding: 0px;
    font-size: 14px;
    line-height: 20px;

    a {
        text-decoration: none;
        color: #042968;
        font-weight: 400;
    }

    p,
    ul {
        margin-bottom: 8px;
    }

    ul {
        list-style: none;
        padding-left: 0px;

        li {
            margin-bottom: 8px;
        }
    }

    .footer {
        &-top-container {
            border-bottom: 1px solid #D4D5D6;
            border-top: 1px solid #D4D5D6;
            padding: 24px 0px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media screen and (max-width: $bp-lg) {
                padding: 24px 16px;
                flex-direction: column;

                .accordion-list {
                    border-bottom: 1px solid #D4D5D6;
                    border-top: 1px solid #D4D5D6;

                    .accordion-label {
                        margin: 0px;
                        padding: 16px 8px;
                        color: #042968;
                        position: relative;
                        line-height: 24px;

                        &::after {
                            content: "";
                            display: block;
                            width: 24px;
                            height: 24px;
                            position: absolute;
                            background-image: url('../images/Icon/arrow.png');
                            right: 8px;
                            top: 16px;
                            -webkit-transition: all 0.3s ease;
                            -moz-transition: all 0.3s ease;
                            -o-transition: all 0.3s ease;
                            -ms-transition: all 0.3s ease;
                            transition: all 0.3s ease;
                        }
                    }

                    ul {
                        height: 0px;
                        overflow: hidden;
                        margin: 0px;
                    }

                    &.active {
                        ul {
                            height: auto;
                            padding: 16px 10px;
                        }

                        .accordion-label {
                            &::after {
                                transform: rotate(90deg);
                            }
                        }
                    }

                    + {
                        .accordion-list {
                            border-top: 0px;
                        }
                    }
                }
            }

        }

        &-bottom-container {
            padding: 16px 120px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media screen and (max-width: $bp-lg) {
                padding: 16px;
                flex-direction: column;
            }

            ul {
                display: flex;
                float: right;
                flex-wrap: wrap;

                li {
                    margin: 0px;
                    line-height: 16px;

                    a {
                        color: #404040;
                        margin: 0 5px;
                        position: relative;
                        font-size: 12px;
                        line-height: 16px;

                        &::after {
                            content: "";
                            position: absolute;
                            width: 1px;
                            height: 10px;
                            display: block;
                            background: #404040;
                            top: 4px;
                            right: -5px;
                        }
                    }

                    &:first-child {
                        a {
                            margin-left: 0px;
                        }
                    }

                    &:last-child {
                        a {
                            margin: 0px;
                            margin-left: 5px;

                            &::after {
                                content: none;
                            }
                        }
                    }

                    a {
                        @media screen and (max-width: $bp-lg) {
                            margin-left: 0px;
                            margin-right: 10px;
                        }
                    }
                }
            }

            p {
                font-size: 12px;
                line-height: 16px;
            }

            .footer-bottom-left {
                text-align: left;
                // width: 100%;

                @media screen and (max-width: $bp-lg) {
                    width: 100%;
                    text-align: left;
                }
            }

            .footer-bottom-right {
                // width: 50%;
                text-align: right;

                @media screen and (max-width: $bp-lg) {
                    width: 100%;
                    text-align: left;
                }
            }
        }

        &-heading {
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 8px;
        }

        &-column {
            &.column-1 {
                display: flex;
                flex-direction: column;

                @media screen and (max-width: $bp-lg) {
                    margin-bottom: 24px;
                }

                .column-1-img {
                    margin-bottom: 16px;
                }

                .column-1-address {
                    margin-bottom: 24px;
                }

                .column-1-applinks {
                    margin-bottom: 24px;

                    .applinks {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        gap: 8px;
                    }

                    .follow-links {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 8px
                    }
                }

            }

            &.link-list {
                .footer-heading {
                    margin-bottom: 16px;

                    @media screen and (max-width: $bp-lg) {
                        margin-bottom: 0px;
                    }
                }

                ul {
                    li {
                        margin-bottom: 16px;

                        @media screen and (max-width: $bp-lg) {
                            margin-bottom: 24px;
                        }

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        a,
                        p {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }

                    @media screen and (max-width: $bp-lg) {
                        padding-top: 16px;
                        padding-bottom: 0px;
                        margin-bottom: 0px;
                    }
                }

                &.doubled-list {
                    @media screen and (min-width: $bp-lg) {
                        min-width: 360px;

                        ul {
                            columns: 2;
                            /* Split into two columns */
                            -webkit-columns: 2;
                            -moz-columns: 2;
                            column-gap: 24px;
                            /* Space between columns */

                            li {
                                list-style-type: none;
                                padding: 5px 0;

                                &:nth-child(7n+1) {
                                    break-before: column;
                                    /* Move to the next column after every 7 items */
                                }
                            }
                        }
                    }
                }
            }
        }

        &-links {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            gap: 24px;

            @media screen and (max-width: $bp-lg) {
                gap: 24px 16px;
                flex-wrap: wrap;
                justify-content: flex-start;
                padding: 0 8px;
            }

            .footer-column {
                min-width: 180px;

                @media screen and (max-width: $bp-lg) {
                    min-width: 0px;
                    width: calc(50% - 8px);
                    border-top: 1px solid #D4D5D6;
                }
            }
        }
    }
}