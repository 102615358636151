// Basic gallery styling
.gallery-container {
  
  width: 100%;
      @media screen and (max-width: 768px) {
          padding: 0px 7.5px;
      }
    &.masonry-layout {
      display: flex;
      flex-wrap: wrap;
      gap: 0px;
      width: 100%;
      .gallery-item{
        width: calc(33.33% - 16px);
        margin-bottom: 24px;
        @media screen and (max-width: 768px) {
          width: calc(50% - 8px);
          margin-bottom: 16px;
        }
      }
    }
  
    .gallery-item {
      position: relative;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
  
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
    
  }
  
  // Lightbox styling
  .lightbox-wrapper {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
  
    .lightbox {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      max-width: 1200px;
      height: auto;
      max-height: 100vh;
      background-color: white;
      padding: 40px;
      color: #404040;
      @media screen and (max-width: 768px) {
        padding: 40px 24px;
        transform:none;
        left: 0px;
        top:0px;
        width: 100%;
        height: 100%;
      }
  
      .lightbox-controls {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 12px;
          padding-left: 8px;
          padding-right: 8px;
        }
      }
  
      .lightbox-header {
        text-align: right;
        position: absolute;
        right: 40px;
        top: 0px;
        @media screen and (max-width: 768px) {
          right: 24px;
        }
  
        .close {
          font-size: 14px;
          cursor: pointer;
          line-height: 20px;
          padding: 10px 22px 10px 8px;
          display: block;
          position: relative;
          font-weight: 400;
          &::before{
            content: "";
            background-image: url(../images/Icon/close_small.svg);
            width: 22px;
            height: 22px;
            display: block;
            position: absolute;
            right: 0px;
            background-repeat: no-repeat;
            background-position: center;
            top: 9px;
          }
        }
      }
      .lightbox-content{
        margin-bottom: 16px;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        display: flex;
        justify-content: center;
        overflow: hidden;
        border-radius: 8px;
        .lightbox-img {
          min-width: 100%;
          height: auto;
          display: block;
          /* margin: 0 auto; */
          object-fit: cover;
          }
      }
  
     .lightbox-controls {
        max-width: 100%;
        margin: 0 auto;
  
        .lightbox-title {
          font-size: 16px;
          line-height: 24px;
          margin: 0;
          font-weight: 700;
        }
  
        .lightbox-description {
          font-size: 14px;
          line-height: 20px;
          margin: 0;
        }
  
       
  
        .lightbox-caption {
          text-align: left;
          padding-right: 40px;
          @media screen and (max-width: 768px) {
            padding-right: 0px;
          }
        }
      }
  
      .lightbox-footer {
        display: flex;
        gap: 24px;
        @media screen and (max-width: 768px) {
          justify-content: space-between;
        }
  
        .prev,
        .next {
          cursor: pointer;
          padding: 10px;
          width: 32px;
          height: 32px;
          border: 2px solid #042968;
          border-radius: 50px;
          position: relative;
  
          &::before {
            content: "";
            background-image: url(../images/Icon/angle-next.svg);
            width: 28px;
            height: 28px;
            display: inline-block;
            position: absolute;
            left: 0px;
            top: 0px;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .prev{
            transform: rotate(180deg);
        }
  
        // .next::before {
        //   left: 1px;
        // }
  
        // .prev::before {
        //   left: 1px;
        // }
      }
  
      span {
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
  
        &.lightbox-pagination {
          display: flex;
  
          > span {
            width: 24px;
            display: block;
            text-align: center;
          }
          @media screen and (max-width: 768px) {
          #current-index {
              margin-right: 10px;
          }
          
          #total-count {
              margin-left: 10px;
          }
          }
        }
      }
    }
  }
  

  /* Basic styling for the gallery container */
.gallery-container {
  display: block;
  margin: 40px 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
    &.gallery-two {
      grid-template-columns: repeat(2, 1fr);
      display: flex;
    }
    &.default-layout{
      .gallery-item {
        aspect-ratio: 16 / 9;
      }
    }
}

/* Styling for gallery items */
.gallery-item {
  display: flex;
  width: 100%;
}

.gallery-item img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Responsive behavior: adjust column count for smaller screens */
@media screen and (max-width: 1024px) {
  .gallery-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}

@media screen and (max-width: 480px) {
  .gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
